import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'; 
import './assets/castTiming.css';  
import './assets/css/togglable.css'; 
import './assets/css/carousel.css'; 
import { Link } from "react-router-dom";
import $ from "jquery";

import analyticalTimingData from './assets/data/analyticalTimingData'
import {castChartSetup} from './assets/module/castChartSetup';

import bellcurve from 'highcharts/modules/histogram-bellcurve';
 
import Highcharts from 'highcharts';
//const ReactHighcharts = require('react-highcharts'); // Expects that Highcharts was loaded in the code.
//import DeckglMap from '../sub_components/deckglMap/deckglMap.jsx'
//(bellcurve)(ReactHighcharts.Highcharts)

class timingAnalytics extends Component {

  constructor(props) {
    super(props);      
    this.state={
      "MTIE":{} 
    };
  } //end of contructor

  componentDidMount(){
    let that = this; 
    
    let config_PM = this.renderPlots(
      ["pm_x","pm_y","PM","Phase Mean","#e41a1c",6.838445229681979e-07]
      ),
      config_PSD = this.renderPlots(
        ["psd_x","psd_y","PSD","Phase Standard Deviation","#fdcdac",1.1513688436538453e-09]
      ),
      config_FM = this.renderPlots(
        ["fm_x","fm_y","FM","Frequency Mean","#377eb8",0]
      ),
      config_FSD = 
        this.renderPlots(
        ["fsd_x","fsd_y","FSD","Frequency Standard Deviation","#4daf4a",1.5710140376148834e-09]
        ),
      config_DM =  this.renderPlots(
          ["dm_x","dm_y","DM","Drift Mean","#984ea3",3.7679401568251793e-25]
        ),
      config_DSD = this.renderPlots(
          ["dsd_x","dsd_y","DSD","Drift Standard Deviation","#ff7f00",2.661174653007192e-09]
        ),
      config_DDM = this.renderPlots(
        ["ddm_x","ddm_y","DDM","Drift Deviation Mean","#a65628",1.428571428577208e-11]
        ),
     config_DDSD = this.renderPlots(
          ["dsd_x","dsd_y","DSD","Drift Standard Deviation","#ff7f00",2.661174653007192e-09]
      );

         
      
      Highcharts.chart('timingAnalyticsContainer_PM', config_PM); 
      Highcharts.chart('timingAnalyticsContainer_PSD', config_PSD);
      Highcharts.chart('timingAnalyticsContainer_FM', config_FM);
      Highcharts.chart('timingAnalyticsContainer_FSD', config_FSD);
      Highcharts.chart('timingAnalyticsContainer_DM', config_DM);    
      Highcharts.chart('timingAnalyticsContainer_DSD', config_DSD);
      Highcharts.chart('timingAnalyticsContainer_DDM', config_DDM);
      Highcharts.chart('timingAnalyticsContainer_DDSD', config_DDSD);
      


  }

  renderPlots(inputs){
    let xAxis = inputs[0],
    yAxis = inputs[1],
    name = inputs[2],
    title = inputs[3], 
    color = inputs[4],
    highlightValue = inputs[5] || 6.838445229681979e-07,
    highlightIndex = -1;

    //console.log("aaa",xAxis.indexOf(6.838445229681979e-07));

    analyticalTimingData[xAxis].forEach(function(item,index){
      console.log(item);
      if(item>highlightValue && highlightIndex==-1){
        highlightIndex=index;
        console.log("aaa",highlightIndex);
      }
    })


    let config = {
      chart: {
          type: 'column'
      },
      title: {
          text: title,
          x:0,
          margin:0
      },
      subtitle: {
          text: 'Source: CAST'
      },
      xAxis: {
          categories:  analyticalTimingData[xAxis],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: yAxis
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        {
          name: name,
          data: analyticalTimingData[yAxis],
          color:color,
          borderWidth:2,
          zones: [{
            value: 0,
            color: color
            }, 
            {
              value: highlightIndex-4,
              color: color
              },
            {
                value: highlightIndex,
                color: 'black',
                borderWidth:5,
            }, {
                value: highlightIndex+4,
                color: color
            }]
      },
       
    ]
  }
  return config
  }
   

  render() {
  return (
    <div className="castTiming_body row">
        
      <div  className="castTiming_container row">
     
     <div  className="col-sm-12 col-md-12 col-lg-12 row">
              
        <div className="col-sm-3 col-md-3 col-lg-3 row">               
          
          {/*
          <ReactHighcharts config = {                
            this.renderPlots(
              ["pm_x","pm_y","PM","Phase Mean","#e41a1c",6.838445229681979e-07]
              )
            }></ReactHighcharts> 
          */}

            <div id="timingAnalyticsContainer_PM">
            </div>
        </div>                   
                
        <div className="col-sm-3 col-md-3 col-lg-3 row">
          {/*
            <ReactHighcharts config = {
             this.renderPlots(
               ["psd_x","psd_y","PSD","Phase Standard Deviation","#fdcdac",1.1513688436538453e-09]
               )
            }></ReactHighcharts>     */ }
            <div id="timingAnalyticsContainer_PSD">
            </div>

        </div>

        <div className="col-sm-3 col-md-3 col-lg-3 row">
          {/*
          <ReactHighcharts config = {
           
            this.renderPlots(
              ["fm_x","fm_y","FM","Frequency Mean","#377eb8",0]
              )
            }></ReactHighcharts>   
          */} 

            <div id="timingAnalyticsContainer_FM">
            </div>

        </div>
        
        <div className="col-sm-3 col-md-3 col-lg-3 row">               
          {/*
            <ReactHighcharts config = {              
            this.renderPlots(
              ["fsd_x","fsd_y","FSD","Frequency Standard Deviation","#4daf4a",1.5710140376148834e-09]
              )
            }></ReactHighcharts> */
            }

            <div id="timingAnalyticsContainer_FSD">
            </div>

        </div>                   
            
        <div className="col-sm-3 col-md-3 col-lg-3 row">
          {
          /*
          <ReactHighcharts config = {                         
            this.renderPlots(
              ["dm_x","dm_y","DM","Drift Mean","#984ea3",3.7679401568251793e-25]
              )
            }></ReactHighcharts>    */}       
            <div id="timingAnalyticsContainer_DM">
            </div>   

        </div>

        <div className="col-sm-3 col-md-3 col-lg-3 row">
          {/*<ReactHighcharts config = { 
            this.renderPlots(
              ["dsd_x","dsd_y","DSD","Drift Standard Deviation","#ff7f00",2.661174653007192e-09]
              )
            }></ReactHighcharts>   */}
            <div id="timingAnalyticsContainer_DSD">
            </div>                                
        </div>

        <div className="col-sm-3 col-md-3 col-lg-3 row">
          {/*
            <ReactHighcharts config = {
            this.renderPlots(
              ["ddm_x","ddm_y","DDM","Drift Deviation Mean","#a65628",1.428571428577208e-11]
              )
            }></ReactHighcharts>   */}   
            <div id="timingAnalyticsContainer_DDM">
            </div>                      
        </div>

        <div className="col-sm-3 col-md-3 col-lg-3 row">
           {/*
           <ReactHighcharts config = {
            this.renderPlots(
              ["ddsd_x","ddsd_y","DDSD","Drift Deviation Standard Deviation","#f781bf",4.774165169034748e-09]
              )
            }></ReactHighcharts>  
          */}    
            <div id="timingAnalyticsContainer_DDSD">
            </div>                      
        </div>
    </div>    
    
          
      </div>
      
    </div>
    );
  };
};

export default timingAnalytics;
