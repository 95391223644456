 
 import $, { map } from "jquery";
 import * as d3 from "d3"; 
 import {horizonChartSetup} from './d3js4_horizons'
 import apiEvent from "../data/demo/api-event";
 import useTokenCheck from "../../../login/getToken"
 //import * as d3 from "d3v4"; 


 export class stackChartSetup {
    renderBarChart (queryStrings, aggregation){
      console.log(queryStrings)

      //Check for token
      let token = ""
        fetch("/api/user/get_token")
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          return response.json();
        })
        .then(data => {
          // Process the successful response data
          token = data.token
          console.log(token)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Redirect in case of a fetch error
          // window.location.href = "/darknet/darklogin";
        });

      let globalData = "-1";
        /* Data in strings like it would be if imported from a csv */    
      // lookupTable = {};

      //?sensor_id=eq.3&start_timestamp=gte.1634702400000000000&start_timestamp=lte.1634788800000000000
 
      //d3.json('http://127.0.0.1:8081/?url=http://128.219.184.165/api/event' 
      // d3.json('https://darknet.ornl.gov/api/event'+queryStrings
      console.log(queryStrings)
      d3.json('/api/event'+queryStrings)
      .header("Authorization", `Bearer ${token}`)
      .get(function(error, data2)
      {        

        // console.log(data2)

        if(error || data2.length === 0)
        {
          tryLocalFiles(aggregation); //this is the static file version     
          console.log("---bakcup--data22---")
          console.log(error)
          triggerButton (apiEvent); 
          let res1 = parseDataWS (apiEvent);
           let inputData=parseData(res1,aggregation); 
           plot("#stp1_stackChart", null); //this is the actual data 
          // document.getElementById("stp1_stackChart").innerText = "No Data";
          triggerButton (globalData); 
        } else {

          if(typeof(data2)!="undefined"){
            console.log("data2",data2,typeof(data2));
            let res1 = parseDataWS (data2);
            let inputData=parseData(res1,aggregation); 
            //console.log("inputData11", inputData);
            plot("#stp1_stackChart",inputData ); //this is the actual data 

            $(".steps").hide();
            $(".step1").show();

            triggerButton (globalData); 
          }else{

            console.log("---bakcup--data---")
            triggerButton (apiEvent); 
          }
            
              

        }
      });

      function parseDataWS (data) {
        let res = [];
        data.forEach(function(item,index){ 
        
          if(item.hasOwnProperty("event_id")){
            res.push({
              "channel": item["channel"], //"voltage-c",
              "date": item["event_id"].slice(0,10), //2021-06-15",
              "end-timestamp": item["end_timestamp"], //"1623800744536692736",
              "filename": item["event_id"],              
              "group-name": item["group_name"],
              "start-timestamp": item["start_timestamp"],
              "threshold": item["threshold"],
              "time": item["event_id"].split("_")[1],  //2021-08-17_07
              "type": item["type"],
              "id":item["id"],
              "sensor_id":item["sensor_id"]
          });
          //console.log( item["event_id"].slice(0,13))
          }    
          
          
        })
        return res;
      }

      /*
      getURL = 'http://127.0.0.1:8081/?url=http://128.219.184.165/api/event';
      fetch(getURL, { 
        method: 'GET'
      }) .then(response=>response.json())
      .then((data1) => {
        // Format data into corresponding state array.
        console.log(data1)
      });
      */
/*
        
        */
       function tryLocalFiles(aggregation) {
            d3.csv('./data/merge_event.csv', function(data){
              // console.log(data);
              let inputData=parseData(data,aggregation); 
              //console.log("inputData11", inputData);
              plot("#stp1_stackChart",inputData);
              $(".steps").hide();
                $(".step1").show();
                triggerButton (globalData); 
            } );
       }

    }// on init
 
 

}//end of class
 function parseData(rawData,aggregation){
     let empty = {}, res=[], lookupTable={}, checkRepetition=[];
    // console.log(rawData);
    let dataAgg = {
      "day": (item) => { 
          return item["date"] + " 00:00"; 
        },
      "hour": (item) => { 
          let iTime = item["time"].split("-").slice(0, 2).join(":"); 
          return item["date"] +" "+ iTime;
        },
      "week": (item) => {return item["date"] + " 00:00"; }
    }

    //console.log("aggregation",aggregation)
    rawData.forEach(function(item,index){
        //let iTime = item["time"].split("-").slice(0, 2).join(":");        
        //let dt = item["date"]+" "+iTime;
        //let dt = item["date"] + " 00:00"
        //console.log("r",item);
        let dt=dataAgg[aggregation](item);        

        if(!empty.hasOwnProperty(dt)){ 
            empty[dt]=
            {"ptct":0,"epc":0,"date":item["date"],
            "time":item["time"].split("-").slice(0, 3).join(":"),
            "dt":dt, "sensor_id":item["sensor_id"],
            "children":{}};
            
        }
        if(!empty[dt].hasOwnProperty(item["group-name"])){
            empty[dt][item["group-name"]]=0;
        }
        if(checkRepetition.indexOf(item["filename"])==-1){
            empty[dt][item["group-name"]]+=1;
            empty[dt]["children"][item["filename"]]=[item["type"],item["id"]];
            checkRepetition.push(item["filename"]);
        }
        
    })
 
    //here we filter by id
    for(let i in empty){     
        //console.log(empty[i]);
        if(filterByID(empty[i]["sensor_id"])){
          res.push(empty[i]);
          lookupTable[i]=empty[i]["children"]
        }
       
    }

    function filterByID(sensorIDField){
      let loc = $("#sensorDashBySensorLoc").val();
      if(loc=="all"){
        return true;
      }
      if(loc== sensorIDField.toString()){
        return true
      }else{
        return false
      }

    }
    //console.log( res,lookupTable)
    return [res,lookupTable]
 }

function  plot(containerID,datainputs){ 
    $(containerID).empty();
    
 
    var margin = {top: 30, right: 30, bottom: 35, left: 30};
  
    var width = $(containerID).width() - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;
   
    var svg = d3.select(containerID)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
     
      svg.append('text')
      .attr('x', (width / 2))             
      .attr('y', 0 - (margin.top / 3))
      .attr('text-anchor', 'middle')  
      .style('font-size', '16px') 
      .text(
        function(){
          let lb = $("#sensorDashBySensorLoc").val() == "all" ?
           $("#sensorDashBySensorLoc").val() : "#"+$("#sensorDashBySensorLoc").val();
          return "Number of Events for "+lb+" Sensor"
        });

    //var parse = d3.time.format("%Y-%m-%d").parse;
    let parse;
    try {
      parse = d3.time.format("%Y-%m-%d %H:%M").parse;
    } catch (error) {
      parse = d3.timeFormat("%Y-%m-%d %H:%M");
    }
    
    console.log("datainputs",datainputs);
    
    let data= datainputs[0];
    let lookupTable= datainputs[1]; //this is hashed
    // Transpose the data into layers
    let stack;
    try {
      stack = d3.layout.stack()
    } catch (error) {
      stack = d3.stack();
    }
    
    var dataset = stack(["ptct", "epc"].map(function(groupName) {

      return data.map(function(d) {
        //console.log(d.dt, parse(d.dt)); 
        return {x: parse(d.dt), y: +d[groupName], group:groupName, val:d[groupName], dateKey:d["dt"] };
      });

    })
    
    );
  
 
    let scalescaleOrdinal, scaleLinera;
    try{
      scalescaleOrdinal=d3.scale.ordinal();
      scaleLinera=d3.scale.linear();
    }catch{
      scalescaleOrdinal=d3.scaleOrdinal(); 
      scaleLinera=d3.scaleLinear();
    }
    //console.log(dataset[0])
    let dd = dataset[0].map(function(d) { return d.x; });
    // Set x, y and colors
    var x = scalescaleOrdinal
      .domain(dd)
      //.filter(function(d,i){ return !(i%10); } )
      .rangeRoundBands([10, width-10], 0.02);
    
    var y = scaleLinera
      .domain([0, d3.max(dataset, function(d) {  return d3.max(d, function(d) { return d.y0 + d.y; });  })])
      .range([height, 0]);
    
    var colors = ["#fc8d59", "#91bfdb"];
    
    //var colors = {"ptct":"b33040", "epc":"#d25c4d"};    
    // Define and draw axes
    var yAxis = d3.svg.axis()
      .scale(y)
      .orient("left")
      .ticks(5)
      .tickSize(-width, 0, 0)
      .tickFormat( function(d) { return d } );
    
    var xAxis = d3.svg.axis()
      .scale(x)
      .orient("bottom")
      .tickValues(x.domain().filter(function(d, i) { return !(i % 10); }))
      .ticks(10) 
      .tickFormat(d3.time.format("%Y-%m-%d"));

      /*
          g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    // Set the Y axis
    g.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .text(" ");*/
    
    svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -25)
      .attr("x", -100)
      .attr("dy", ".71em")
      .style("font-size", "15px")
      .style("text-anchor", "end")
      .text("Current vs Voltage");;
    
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    
    // Create groups for each series, rects for each segment 
    var groups = svg.selectAll("g.cost")
      .data(dataset)
      .enter().append("g")
      .attr("class", "cost")
      .style("fill", function(d, i) {   
        return colors[i]; });
       
    var rect = groups.selectAll("rect")
      .data(function(d) { return d; })
      .enter()
      .append("rect")
      .attr("x", function(d) { return x(d.x); })
      .attr("y", function(d) { return y(d.y0 + d.y); })
      .attr("height", function(d) { return y(d.y0) - y(d.y0 + d.y); })
      .attr("width", x.rangeBand())
      .on("mouseover", function(d) { 
          d3.select(this).style("fill-opacity", 0.6);
        //  console.log(d)
          changeEvTable(d);
          //tooltip.style("display", null);
         })
      .on("mouseout", function() { 
        d3.select(this).style("fill-opacity", 1);
        
        //tooltip.style("display", "none"); 
    })
        .on("click", function(d) { 
            //console.log(d);
            changeEvTable2(d,lookupTable)
        //lookupTable
        })
      .on("mousemove", function(d) {
        var xPosition = d3.mouse(this)[0] - 15;
        var yPosition = d3.mouse(this)[1] - 25;
        tooltip.style("display", "block");
        tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
        tooltip.select("text").text(d.y+"-"+d.group);
      })
      /*
      var text = groups.selectAll("text")
    .data(function(d) { return d; })
    .enter().append("text")
    .attr("x", function(d, i) { return x(d.x)+(width-margin['left']-margin['right'])/(dataset.length)/10 })
    .attr("y", function(d, i) { return y(d.y0 + d.y)})
    .attr("dy", ".35em")
    .style('fill', 'black')
    .style('font-size', '15px')
    .style("text-anchor", "start")
    .text(function(d, i) { 
        return d.val      
        });*/
   
    
    
    // Draw legend
    var legend = svg.selectAll(".legend")
      .data(colors)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function(d, i) { return "translate(-30," + i * 19 + ")"; });
     
    legend.append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", function(d, i) {return colors.slice().reverse()[i];});
     
    legend.append("text")
      .attr("x", width + 5)
      .attr("y", 9)
      .attr("dy", ".55em")
      .style("text-anchor", "start")
      .style("font-size", "15px")
      .text(function(d, i) { 
         
        switch (i) {
          case 0: return "epc";
          case 1: return "ptct";
      
        }
      });
    
    
    // Prep the tooltip bits, initial display is hidden
    var tooltip = svg.append("g")
      .attr("class", "tooltip")
      .style("display", "block");
        
    tooltip.append("rect")
      .attr("width", 30)
      .attr("height", 20)
      .attr("fill", "black")
      .style("opacity", 0.5);
    
    tooltip.append("text")
      .attr("x", 15)
      .attr("dy", "1.2em")
      .style("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold");
}// end of createOverviewTable

function changeEvTable(d){
    $("#evTable").empty();
   // console.log("d",d)
    let htmlTemplate = 
        `<tr  class='evTableItem evTableItem'>            
            <td>`+d.group+`</td>            
            <td>`+
            d.x+
            `</td> 
            <td>`+
            d.val+
            `</td>            
        </tr>`;
        $("#evTable").append(htmlTemplate);
}

function changeEvTable2(d,lookupTable){
    $("#evTable2").empty();
    //console.log("d",lookupTable[d["dateKey"]]);
    let list = lookupTable[d["dateKey"]];
    //console.log(list);

    for(let i in list){
    let htmlTemplate = 
        `<tr  class='evTableItem evTableItem'>            
            <td>`+i+`</td>
            <td>`+
            list[i][1]+ 
            `</td> 
            <td>`+list[i][0]+`</td>   
            <td> <button 
            class="btn btn-info triggerVisualize " 
            Name=`+list[i][1]+`         
            >Visualize</button> </td>          
        </tr>`;
        $("#evTable2").append(htmlTemplate);
//.toStep2 is supposed to be added to the button
    }
    
}

function triggerButton (globalData) {
 
  $(document).on("click",".triggerVisualize",function(e) {
    let fileName2 = this.name;

    if(globalData === fileName2){      
    }else{
      //.log(fileName2,globalData, globalData === fileName2); 
      globalData = fileName2;
      loadTimeseries(fileName2);

    }
    //2021-06-07_11-13-15-941_voltage-c_fast-transient
  //  2021-06-07_11-13-15-941_voltage-c_fast-transient
});

}

function loadTimeseries(event_id){
  //Check for token
  let token = ""
  fetch("/api/user/get_token")
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    return response.json();
  })
  .then(data => {
    // Process the successful response data
    token = data.token
    console.log(token)
  })
  .catch(error => {
    console.error('Error fetching data:', error);
    // Redirect in case of a fetch error
    // window.location.href = "/darknet/darklogin";
  });
  //let url = "./data/timeseries/"+fileName2; 
  // let url = "https://darknet.ornl.gov/api/measurement?limit=1000&event_id=eq."+event_id; 
  let url = "/api/measurement?limit=1000&event_id=eq."+event_id; 
  //console.log("http://128.219.184.165/api/measurement?event_id=eq.518")
  console.log(url);
  d3.json(url)
  .header("Authorization", `Bearer ${token}`)
  .get(function(error, rawData){
    if(error || rawData.length === 0)
        {
          console.log(error)
        }else{
    console.log(rawData);
    rawData.sort((a, b) => (a.time > b.time) ? 1 : -1)
    let data = optimization(rawData);
 
    let headerArray1 = [ 
                        'epc_la', 'epc_lb','epc_lc',                          
                        'ptct_la', 'ptct_lb', 'ptct_lc'],
    headerArray2 = ['epc_va','epc_vb', 'epc_vc',      
    'ptct_va', 'ptct_vb',  'ptct_vc'
     ];
    //console.log(headerText+data);
    //let res = csvJSON(headerText+data);

    let data_i_e = dataFieldExtracter(data,['epc_la', 'epc_lb','epc_lc','time']);
    let data_v_e = dataFieldExtracter(data,['epc_va', 'epc_vb','epc_vc','time']);
    let data_i_p = dataFieldExtracter(data,['ptct_la', 'ptct_lb', 'ptct_lc','time']);
    let data_v_p = dataFieldExtracter(data,['ptct_va', 'ptct_vb',  'ptct_vc','time']);
    
    //console.log(data_i);
    d3multiLineChart("#step2Charts_i_e",data_i_e,"currentE","Current");
    d3multiLineChart("#step2Charts_v_e",data_v_e,"voltageE","Voltage"); 
    d3multiLineChart("#step2Charts_i_p",data_i_p,"currentP","Current");
    d3multiLineChart("#step2Charts_v_p",data_v_p,"voltageP","Voltage"); 
    let horizonChartSetupcos = new horizonChartSetup();    
    horizonChartSetupcos.renderHorizon("#step2Charts_horizon1",headerArray1,rawData); 
    horizonChartSetupcos.renderHorizon("#step2Charts_horizon2",headerArray2,rawData); 
   
    switchIorV();


    }});

    function switchIorV(){
      $(".step2Charts_i_charts, .step2Charts_v_charts").hide();
      let dispVal = $("#sensorDashVorI").val();
      $("."+dispVal).show();    
       
      
    }
 
    function optimization(json){
      let res = [];
      //let para = Math.floor((json.length)/1000);
      json.forEach(function(item,index){
        /*if(item.time%(100*5)==0){
          res.push(item); 
        }*/
        //if(index%(1000)==0){
          res.push(item); 
        //}
      });
      //this is very important here //need to be address in web services
      
      return res;
    };

    function dataFieldExtracter(json,field){
      let res = [];
      json.forEach(function(item,index){
        let temp={};
        for(let i in item){
          if(field.indexOf(i)!=-1){
            temp[i]=item[i]
          }
        }//iterate fields
        //console.log(temp);
        res.push(temp);
      });
      return res;
    }

    function csvJSON(csv) {
      const lines = csv.split('\n')
      const result = []
      const headers = lines[0].split(',')
    
      for (let i = 1; i < lines.length; i++) { 
      
          if (!lines[i])
              continue
          const obj = {}
          const currentline = lines[i].split(',')
    
          for (let j = 0; j < headers.length; j++) {
              obj[headers[j]] = Number(currentline[j])
          }
         if(i%5===0){
          result.push(obj)
         }        
    };
    result.shift();
    return result
    } 
}


function d3multiLineChart(containerID,data,plotName,plotTitle){ 
  //console.log("empty---"); 
  var margin = {top: 20, right: 19, bottom: 35, left: 5};
 
  var width = $(containerID).width() - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;
  $(containerID).empty();
  var svg = d3.select(containerID)
    .append("svg")
    .attr("width", width)
    .attr("height", height + margin.top + margin.bottom),
    margin = { top: 20, right: 50, bottom: 30, left: 50 },
    width = +svg.attr('width') - margin.left - margin.right,
    height = +svg.attr('height') - margin.top - margin.bottom,
    g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  // Graph title
  g.append('text')
    .attr('x', (width / 2))             
    .attr('y', 0 - (margin.top / 3))
    .attr('text-anchor', 'middle')  
    .style('font-size', '16px') 
    .text(plotTitle);
  // Function to convert a string into a time
  var parseTime = d3.time.format('%Y-%m-%d %H:%M').parse;
  // Function to show specific time format
  var formatTime = d3.time.format('%e %B');
  
  // Set the X scale
  var x = d3.time.scale().range([0, width], 0.5);
  // Set the Y scale
  var y = d3.scale.linear().range([height, 0]);
  // Set the color scale
  var color = d3.scale.category10();
  
  var xAxis = d3.svg.axis()
  .scale(x)
  .orient("bottom");
  
  var yAxis = d3.svg.axis()
  .scale(y)
  .orient("left");
  
  var line = d3.svg.line()
  // .interpolate("basis")
  .x(function(d) {
    return x(d.date);
  })
  .y(function(d) {
    return y(d.worth);
  });
    
    // load the data
  //d3.json("data.json", function(error, data) {
    // Select the important columns
    //console.log(data)
    color.domain(d3.keys(data[0]).filter(function(key) {
        return key !== "time" && key !== "_id";
    }));
    // Correct the types
    data.forEach(function(d) {
      //d.date = parseTime(d.time);
      //let tempNum = Number(1629499000000)+Number(d.time*100000);
      //d.date = new Date(tempNum);
      d.date= new Date(d.time/1000);
       
      //d.date = new Date(d.time);
      
    });
    //console.log(data);
  
    var currencies = color.domain().map(function(name) {
      return {
        name: name,
        values: data.map(function(d) {
          return {
            date: d.date,
            worth: +d[name]
          };
        })
      };
    });
    //console.log(JSON.stringfycurrencies)
    console.log(currencies)  
    //return false
    // Set the X domain
    x.domain(d3.extent(data, function(d) {
      return d.date;
    }));
    // Set the Y domain
    y.domain([
      d3.min(currencies, function(c) {
        return d3.min(c.values, function(v) {
          return v.worth;
        });
      }),
      d3.max(currencies, function(c) {
        return d3.max(c.values, function(v) {
          return v.worth;
        });
      })
    ]);
    // Set the X axis
    g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    // Set the Y axis
    g.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .text(" ");
  
    // Draw the lines
    var currency = g.selectAll(".currency"+plotName)
      .data(currencies)
      .enter().append("g")
      .attr("class", "currency"+plotName);
  
    currency.append("path")
      .attr("class", "line sensorDash-path")
      .attr("d", function(d) {
        return line(d.values);
      })
      .style("stroke", function(d) {
        return color(d.name);
      });
    // Add the circles
    currency.append("g").selectAll("circle")
      .data(function(d){return d.values})
      .enter()
      .append("circle")
      .attr("r", 2)
      .attr("cx", function(dd){return x(dd.date)})
      .attr("cy", function(dd){return y(dd.worth)})
      .attr("fill", "none")
      .attr("stroke", function(d){return color(this.parentNode.__data__.name)});
    // Add label to the end of the line
    currency.append("text")
      .attr("class", "label"+plotName)
      .datum(function (d) {
        return {
          name: d.name,
          value: d.values[d.values.length - 1]
        };
      })
      .attr("transform", function (d) {
        return "translate(" + x(d.value.date) + "," + y(d.value.worth) + ")";
      })
      .attr("x", 3)
      .attr("dy", ".35em")
      .style('font-size', '14px') 
      .text(function (d) {
        return d.name;
    });
  
  // Add the mouse line
  var mouseG = g.append("g")
    .attr("class", "mouse-over-effects"+plotName);
  
  mouseG.append("path")
    .attr("class", "mouse-line"+plotName+" sensorDash-path")
    .style("stroke", "black")
    .style("stroke-width", "1px")
    .style("opacity", "0");
  
  var lines = document.getElementsByClassName('line');
  
  var mousePerLine = mouseG.selectAll('.mouse-per-line'+plotName)
    .data(currencies)
    .enter()
    .append("g")
    .attr("class", "mouse-per-line"+plotName);
  
  mousePerLine.append("circle")
    .attr("r", 7)
    .style("stroke", function (d) {
      return color(d.name);
    })
    .style("fill", "none")
    .style("stroke-width", "2px")
    .style("opacity", "0");
  
  mousePerLine.append("text")
      .attr("class", "hover-text"+plotName)
      .attr("dy", "-1em")
      .attr("transform", "translate(10,3)");
  
  // Append a rect to catch mouse movements on canvas
  mouseG.append('svg:rect') 
    .attr('width', width) 
    .attr('height', height)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('mouseout', function () { // on mouse out hide line, circles and text
      d3.select(".mouse-line"+plotName)
        .style("opacity", "0");
      d3.selectAll(".mouse-per-line"+plotName+" circle")
        .style("opacity", "0");
      d3.selectAll(".mouse-per-line"+plotName+" text")
        .style("opacity", "0");
    })
    .on('mouseover', function () { // on mouse in show line, circles and text
      d3.select(".mouse-line"+plotName)
        .style("opacity", "1");
      d3.selectAll(".mouse-per-line"+plotName+" circle")
        .style("opacity", "1");
      d3.selectAll(".mouse-per-line"+plotName+" text")
        .style("opacity", "1");
    })
    .on('mousemove', function () { // mouse moving over canvas
      var mouse = d3.mouse(this);
  
      d3.selectAll(".mouse-per-line"+plotName)
        .attr("transform", function (d, i) {
  
          var xDate = x.invert(mouse[0]),
            bisect = d3.bisector(function (d) { return d.date; }).left,
          idx = bisect(d.values, xDate);
  
          d3.select(this).select('text')
            .text(function(d){
              // console.log(d);
              if(d.values.hasOwnProperty(idx)){ 
              if(d.values[idx].hasOwnProperty("worth")){
                let num = d.values[idx].worth || 0;
                
                return  d["name"]+"-"+y.invert(y(num)).toFixed(2)
              }else{
                return 0;
              }    
            }          
            }
             
              ).style("font-size", "15px");
  
          d3.select(".mouse-line"+plotName)
            .attr("d", function () {
              if(d.values.hasOwnProperty(idx)){
                var data = "M" + x(d.values[idx].date) + "," + height;
                data += " " + x(d.values[idx].date) + "," + 0;
                return data;
              }  
            });
            if(d.values.hasOwnProperty(idx)){
          return "translate(" + x(d.values[idx].date) + "," + y(d.values[idx].worth) + ")";
            }
        });
    });
      
  
 // });
  
}