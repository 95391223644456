import React, {useState}  from "react";
import PropTypes from "prop-types";
import DarknetTheme from './assets/img/darknet_home_theme.png';

//const YoutubeEmbed = ({ embedId, height, width }) => {

  class YoutubeEmbed extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        videoWidth:639
      };

      this.hideStaticBackground = this.hideStaticBackground.bind(this)
    }

    componentDidMount () {
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions); 
  
    };

    
    hideStaticBackground () {
      this.setState({loading:false})
    };

    updateDimensions = () => {
      
      if(window.innerWidth>750){
        this.setState({ videoWidth: 639 });
      }else{
        this.setState({ videoWidth: (window.innerWidth)-150 });
        console.log(this.state.videoWidth);
      }     
    };
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
      const { width,height } = this.props;
      const embedId = "Yho3ab_-ukI?autoplay=1&loop=1&rel=0&playlist=Yho3ab_-ukI";
     
    return (
  <div className="video-responsive">
    {this.state.widthloading ? (
          <img src={DarknetTheme} 
          width={this.state.videoWidth+"px"}  
          height="360px"
           ></img>          
        ) : null}
    
    <iframe
      width={this.state.videoWidth}  
      height={height}     
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; 
      clipboard-write; encrypted-media; 
      gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      onLoad={this.hideStaticBackground}
      />
    </div>
    ) 
    };
  };


/*YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};*/

export default YoutubeEmbed;
