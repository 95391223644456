import React from "react";
import { Link } from "react-router-dom";
import './assets/navbar.css';  
import logo from './assets/img/doeLogo.png';

import '../../../node_modules/bootstrap/dist/css/bootstrap.css';  
import '../../../node_modules/jquery/src/jquery'; //for bootstrap.min.js
import '../../../node_modules/bootstrap/dist/js/bootstrap'; //for bootstrap.min.js
import { useLocation } from 'react-router-dom'


const DynamicContent = () => {
  const list =[
   ["/document?content=DarkNetOverallPrimerFinal","DarkNet Overview"],
   ["/document?content=DarkNetCyberResilienceFinal","Cyber Resilience and Edge Detection"],
   ["/document?content=DarkNetEdgeDetectionPrimerFinal","Edge Detection of Grid Anomalies"],
   ["/document?content=DarkNetQuantumPrimerFinal","Quantum Communications for Grid Security"],
   ["/document?content=DECCTestbedDOEFactSheetfinal","Anomaly Detection Testbed"],
   //["/document?content=DarkNetDLTPrimerFINAL","Grid Guard Distributed Ledger Technology"],   
   ["/document?content=GridGuard_fnl","Grid Guard Distributed Ledger Technology"], 
   ["/document?content=Signature_Libraries","Signature Libraries"], 
  ];
  const location = useLocation();
  let path = location.pathname;


  return(list.map(function(d, idx){  
    return (<p><Link to={d[0]} 
      className='nav-text-dropdown'>{d[1]} </Link>
      </p>)   
  }))
}//end of dynamic content

const DynamicTitle = () => {
  const location = useLocation();
  let path = location.pathname;

  if (path.split("/").indexOf("darknet")!== -1){
    return ["Darknet Dashboard","nav-customcolor","/"] 
  }
  return ["Darknet Dashboard","nav-customcolor","/"];
}

// const DynamicCastDark = () => {
//   const location = useLocation();
//   let path = location.pathname;

//   if (path.split("/").indexOf("demo")!=-1){ //this is demo
//       if (path.split("/").indexOf("darknet")!=-1){ //is dark
//         return ["Go to Cast","demo/cast"] 
//       }

//       if (path.split("/").indexOf("cast")!=-1){
//        // alert("demo/darknet")
//         return ["Go to Darknet","demo/darknet"]
//       }
//   }else{ //this is not demo
//     if (path.split("/").indexOf("darknet")!=-1){ //is dark
//       return ["Go to Cast","/cast"] 
//     }

//     if (path.split("/").indexOf("cast")!=-1){
//       return ["Go to Darknet","/darknet"]
//     }
//   }
//   return ["Go to Cast","/cast"] 

 
// }

const NavBar = () => {
  return (
    <nav className=
    {"navbar navbar-default navbar-expand navbar-dark nav-customcolor"}>
     <div className="navbar-brand brand ">
                       
          <img src={logo}
           className="ornl-logo">
            </img>          
          <span className="box-header-text">
            { DynamicTitle()[0] }</span>              
    
    </div>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

   <div className="navbar-collapse collapse" id="navbarNavDropdown">
    <ul className="nav navbar-nav  ml-auto">
      
      <li className="nav-item active ">
        <div className="nav-link"> <Link to={DynamicTitle()[2]} className="nav-text">Home</Link></div>        
      </li>   
 
 <li className="nav-item dropdown">
        <div className="nav-link dropdown-toggle nav-text"  
        href="#" id="navbarDropdown" role="button"  
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Documentation
        </div>
        <div className="dropdown-menu dropdown-menu-style" aria-labelledby="navbarDropdown">
       
        {DynamicContent()}
        </div>
      </li>
      {/* <li className="nav-item active ">
        <div className="nav-link"> <Link to={DynamicCastDark()[1]} className="nav-text">{DynamicCastDark()[0]}</Link></div>        
      </li>    */}

        
   
    </ul>
    </div>
    
    </nav>
    
  );
};

export default NavBar;
