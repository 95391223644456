import React, { Component, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "./components/navbar/navbar";
import Products from "./components/documentation/documents";
import Posts from "./components/posts";

import {ReactTooltip} from 'react-tooltip';
import Dashboard from "./components/admin/dashboard";
import ProductDetails from "./components/productDetails";
import NotFound from "./components/notFound";
import "./App.css";

import Home from "./components/home/home";
import dashboard from "./components/dashboard/dashboard";

import timingAnalytics from "./components/timingAnalytics/timingAnalytics"

import { useHistory } from 'react-router-dom';
import { json } from "d3";
import Login from "./components/login/login";

const App = () => {
  const history = useHistory();

  // useEffect(() => {
  //   // Fetch data from the endpoint
  //   fetch('/api/user/get_token')
  //     .then(response => response.json())
  //     .then(response => console.log(response.json()))
  //     .then(data => {
  //       // Check if 'email' token exists in the response data
  //       if (data && data.email) {
  //         // Redirect to one place if 'email' token exists
  //         history.push('/darknet/dashboard');
  //       } else {
  //         // Redirect to another place if 'email' token doesn't exist
  //         history.push('/darklogin');
  //       }
  //     })
  //     .catch(error => {
  //       // Handle fetch error here
  //       console.error('Error fetching data:', error);
  //       // Redirect to an error page or default location if fetch fails
  //       history.push('/error-page');
  //     });
  // }, [history]);

    return (
      <div>
        <NavBar />
        <div className="content">
          <Switch>
            <Route path="/products/:id" component={ProductDetails} />
            <Route
              path="/document"
              render={props => <Products sortBy="newest" {...props} />}
            />
            
            <Route path="/posts/:year?/:month?" component={Posts} />
            <Route path="/admin" component={Dashboard} />
            
             {/* <Route exact path="/">
              <Redirect to="/darknet/" />
              </Route> */}

{/*               
                <Route path='/darklogin' component={() => { 
                                // window.location.href = 'https://darknet.ornl.gov/web/darklogin'; 
                                window.location.href = '/api/user/darklogin'; 
                                return null;
                            }}/>
              
              <Route path='/darknet/darklogin' component={() => { 
                                window.location.href = '/api/user/darklogin'; 
                                return null;
                            }}/>             */}

            <Route path="/" exact component={Home} />  
            <Route path="/darknet/darklogin" exact component={Login} />  
            <Route path="/darknet/dashboard" exact component={dashboard} />  
            <Route path="/demo/darknet/" exact component={Home} />
            <Route path="/demo/darknet/dashboard" exact component={dashboard} />  

            <Route path="/not-found" component={NotFound} />
            <Redirect from="/messages" to="/posts" />
          
            <Route exact path="/demo/darknet/darklogin">
              <Redirect to="/demo/darknet/dashboard" />
              </Route> 
              <Route exact path="/demo/demo/darknet">
              <Redirect to="/demo/darknet" />
              </Route> 
            <Redirect to="/not-found" />
          </Switch>
        </div>
      </div>
    );
  }

export default App;
