import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import documentation from './assets/documentation/DarkNet_Documentation_Dashboard.pdf';
import castDoeFactsheet2Page from './assets/documentation/CAST_DOE_FactSheet_2page_20211208.pdf';
import DarkNetCyberResilienceFinal from './assets/documentation/DarkNet_Cyber_Resilience_Final.pdf';
import DarkNetEdgeDetectionPrimerFinal from './assets/documentation/DarkNet_Edge_Detection_Primer_Final.pdf';
import DarkNetOverallPrimerFinal from './assets/documentation/DarkNet_Overall_Primer_Final.pdf'

import DarkNetDLTPrimerFINAL from './assets/documentation/DarkNet_DLT_Primer_FINAL.pdf';
import DarkNetQuantumPrimerFinal from './assets/documentation/DarkNet_Quantum_Primer_Final.pdf';
import DECCTestbedDOEFactSheetfinal from './assets/documentation/DECC_Testbed_DOE_Fact_Sheet_final.pdf';

import './assets/documentation.css';  
import G00196_GridGuard_fnl from "./assets/documentation/22-G00196_(GridGuard)_fnl.pdf";
import G00322_Signature_Libraries_2 from "./assets/documentation/22-G00322_Signature_Libraries_2.pdf";

import castDoeFactsheet2Page_2 from "./assets/documentation-2/22-G00191 (DarkNet) fnl.pdf"
import G00196_GridGuard_fnl_2 from "./assets/documentation-2/22-G00196 (GridGuard) fnl.pdf"
import DarkNetCyberResilienceFinal_2 from "./assets/documentation-2/22-G00210 Cyber Resilience-fnl.pdf"
import DarkNetQuantumPrimerFinal_2 from "./assets/documentation-2/22-G00211 (Quantum Comm) fnl 1.pdf"
//"./assets/documentation/22-G00211 (Quantum Comm) fnl.pdf"
import DECCTestbedDOEFactSheetfinal_2 from "./assets/documentation-2/22-G00212 (DECC Testbed)2.pdf"
import DarkNetEdgeDetectionPrimerFinal_2 from "./assets/documentation-2/22-G00236 (Edge Detection) fnl.pdf"
import G00322_Signature_Libraries_3 from "./assets/documentation-2/22-G00322 (Signature Libraries) 2.pdf"


class Products extends Component {

  state = {
    products: [
      { id: 1, name: "Product 1" },
      { id: 2, name: "Product 2" },
      { id: 3, name: "Product 3" }
    ]
  };

  componentDidMount(){
   // const location = useLocation();
   // console.log(location.pathname);
   this.contentSwitcher();
    
  }

  contentSwitcher(){
    const query = new URLSearchParams(this.props.location.search);
    const content = query.get('content')
    console.log("content",content)//123   

    let contentList={
      "quickguide":documentation,
      "castDoeFactsheet2Page":castDoeFactsheet2Page_2,
      "DarkNetCyberResilienceFinal":DarkNetCyberResilienceFinal_2,
      "DarkNetEdgeDetectionPrimerFinal":DarkNetEdgeDetectionPrimerFinal_2,
      "DarkNetOverallPrimerFinal":DarkNetOverallPrimerFinal,
      "DarkNetDLTPrimerFINAL":DarkNetDLTPrimerFINAL,
      "DarkNetQuantumPrimerFinal":DarkNetQuantumPrimerFinal_2,
      "DECCTestbedDOEFactSheetfinal":DECCTestbedDOEFactSheetfinal_2,
      "GridGuard_fnl":G00196_GridGuard_fnl_2,
      "Signature_Libraries":G00322_Signature_Libraries_3

    }

    return contentList[content]
     
  }
 
  
  render() {    
    return (
      <div className="home-background documentation-fullbk">
        <h2> </h2>
        <ul>
          {/*this.state.products.map(product => (
            <li key={product.id}>
              <Link to={`/products/${product.id}`}>{product.name}</Link>
            </li>
          ))*/}
        </ul>
        {aa()}
        <object width="100%" height="90%" className="documentation-centercol"
        data={ this.contentSwitcher()} type="application/pdf">   </object>
      </div>
      
    );
    function aa (){
      console.log("a")

    }
  }
}

export default Products;
