
const sensorDashBoardEventLocation = {
  "type": "FeatureCollection",
  "name": "locations",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  "features": [
  { "type": "Feature", "properties": { "Location": "PG&E", "ToolTipAnchor":"top", "Latitude": 37.4688851, "Longitude": -122.4305633 }, "geometry": { "type": "Point", "coordinates": [ -122.4305633, 37.4688851 ] } },
  { "type": "Feature", "properties": { "Location": "EPB", "ToolTipAnchor":"bottom", "Latitude": 35.051452, "Longitude": -85.2939351 }, "geometry": { "type": "Point", "coordinates": [ -85.2939351, 35.051452 ] } },
  { "type": "Feature", "properties": { "Location": "DECC", "ToolTipAnchor":"top", "Latitude": 35.9297425, "Longitude": -84.3175526 }, "geometry": { "type": "Point", "coordinates": [ -84.3175526, 35.9297425 ] } }
  ]
  }
  
  export default sensorDashBoardEventLocation;

  