 
 import $, { map } from "jquery";
 //import * as d3 from "d3"; 
 import * as d3 from "d3v4"; 
 //import * as hoz from './d3v4_horizon_lib'
 //import { horizonChart } from "d3-horizon-chart"
 var dhc = require('d3-horizon-chart');
 export class horizonChartSetup {

    renderHorizon (containerID,dataHeader,data){
         drawSampleHorizon(containerID,dataHeader,data)
    }// on init
 
 

}//end of class
 function drawSampleHorizon(containerID,dataHeader,inputData){
    //https://bl.ocks.org/kmandov/dcb94f02e71e3560eb3255f2de3120e4
   
      console.log("inputData",inputData);
      var q = d3.queue();

      /*
      ['AAPL', 'BIDU', 'SINA', 'GOOG', 'MSFT', 'YHOO', 'ADBE', 'REDF', 'INSP', 'IACI', 'AVID', 'CCUR', 'DELL', 'DGII', 'HPQ', 'SGI', 'SMCI', 'SNDK', 'SYNA'].forEach(function(stock) {
          q.defer(loadStockData, stock);
      });
      */

      dataHeader.forEach(function(stock) {
        q.defer(loadStockData, stock);
    });

  q.awaitAll(function(error, stocks) {
      if (error) throw error;

      let stepsize=Math.floor($(containerID).width()/stocks.length)*0.00555;
      console.log(stepsize);

    let chartsetup = 
      d3.select(containerID).selectAll('.horizon')
          .data(stocks)
          .enter()
          .append('div')          
          .attr('class', 'horizon col-md-12 col-sm-12 col-lg-12 horizonDiv')
          .each(function(d) {
            /*
                  d3.horizonChart()
                  .title(d.stock)
                  .call(this, d.values);
            */
           console.log("stepsize",stepsize)
            dhc.horizonChart()
            .title(d.stock)
            .height(120)
            .step(stepsize)    
            .spacing(0)      
             
            .call(this, d.values)
             
              
          });
          console.log(dhc.horizonChart())
      
       
  });
  
      
    function loadStockData(stock, callback) {
     // d3.csv('https://bost.ocks.org/mike/cubism/intro/stocks/' + stock + '.csv', function(rows) {
       let rows = inputData;
              rows = rows.map(function(d) {
                
                //let tempNum = Number(1629499000000)+Number(d.time*100000),
                let timeField = new Date(d.time/1000); 
              return ["d3.timeParse(timeField)",d[stock]];
              //return [d3.timeParse(d.Date), +d.Open];  //this is sample
          })/*
          .filter(function(d) {
              console.log("filter",d[1],d)
              return d[1]; 
          })*/
          //.reverse();

          //console.log("rows",stock,rows)
          /*var date = rows[0][0],
              compare = rows[400][1],
              value = rows[0][1],*/ 
            var  values = [];

          rows.forEach(function(d, i ) {
              //values.push(value = (d[1] - compare) / compare);  //this is sample
              //console.log(d)
              values.push(d[1]);
          });
          //console.log("fkstk",values)
          callback(null, {
              'stock': stock,
              'values': values
          });
     // });
    }
}
