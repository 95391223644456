
const analyticalTimingData = {
  "pm_x":[-1.55927562e-06,-1.52927884e-06,-1.49928206e-06,-1.46928528e-06
    ,-1.43928850e-06,-1.40929172e-06,-1.37929494e-06,-1.34929816e-06
    ,-1.31930138e-06,-1.28930460e-06,-1.25930782e-06,-1.22931105e-06
    ,-1.19931427e-06,-1.16931749e-06,-1.13932071e-06,-1.10932393e-06
    ,-1.07932715e-06,-1.04933037e-06,-1.01933359e-06,-9.89336811e-07
    ,-9.59340031e-07,-9.29343252e-07,-8.99346473e-07,-8.69349693e-07
    ,-8.39352914e-07,-8.09356135e-07,-7.79359355e-07,-7.49362576e-07
    ,-7.19365797e-07,-6.89369017e-07,-6.59372238e-07,-6.29375459e-07
    ,-5.99378679e-07,-5.69381900e-07,-5.39385121e-07,-5.09388341e-07
    ,-4.79391562e-07,-4.49394783e-07,-4.19398003e-07,-3.89401224e-07
    ,-3.59404445e-07,-3.29407665e-07,-2.99410886e-07,-2.69414107e-07
    ,-2.39417327e-07,-2.09420548e-07,-1.79423769e-07,-1.49426989e-07
    ,-1.19430210e-07,-8.94334305e-08,-5.94366511e-08,-2.94398718e-08
    ,5.56907539e-10,3.05536869e-08,6.05504662e-08,9.05472456e-08
    ,1.20544025e-07,1.50540804e-07,1.80537584e-07,2.10534363e-07
    ,2.40531142e-07,2.70527922e-07,3.00524701e-07,3.30521480e-07
    ,3.60518260e-07,3.90515039e-07,4.20511818e-07,4.50508598e-07
    ,4.80505377e-07,5.10502156e-07,5.40498936e-07,5.70495715e-07
    ,6.00492494e-07,6.30489274e-07,6.60486053e-07,6.90482832e-07
    ,7.20479612e-07,7.50476391e-07,7.80473170e-07,8.10469950e-07
    ,8.40466729e-07,8.70463509e-07,9.00460288e-07,9.30457067e-07
    ,9.60453847e-07,9.90450626e-07,1.02044741e-06,1.05044418e-06
    ,1.08044096e-06,1.11043774e-06,1.14043452e-06,1.17043130e-06
    ,1.20042808e-06,1.23042486e-06,1.26042164e-06,1.29041842e-06
    ,1.32041520e-06,1.35041198e-06,1.38040876e-06,1.41040554e-06
    ,1.44040232e-06],
    "pm_y":[2.,1.,4.,1.,1.,0.,2.,0.,1.,2.,0.,0.,0.,1.
      ,1.,0.,1.,0.,0.,2.,2.,1.,1.,1.,4.,3.,4.,5.
      ,1.,4.,5.,7.,5.,13.,12.,14.,15.,7.,12.,15.,23.,16.
      ,21.,23.,42.,27.,46.,43.,53.,71.,95.,97.,99.,128.,137.,174.
      ,179.,186.,215.,288.,288.,280.,306.,347.,346.,406.,435.,475.,419.,493.
      ,462.,330.,336.,283.,226.,187.,149.,121.,90.,106.,68.,63.,57.,41.
      ,43.,39.,30.,22.,20.,18.,20.,6.,6.,3.,2.,1.,1.,1.
      ,0.,1.],
      "psd_x":[8.87622381e-10,2.65700902e-09,4.42639566e-09,6.19578230e-09
      ,7.96516894e-09,9.73455558e-09,1.15039422e-08,1.32733289e-08
      ,1.50427155e-08,1.68121021e-08,1.85814888e-08,2.03508754e-08
      ,2.21202621e-08,2.38896487e-08,2.56590353e-08,2.74284220e-08
      ,2.91978086e-08,3.09671953e-08,3.27365819e-08,3.45059685e-08
      ,3.62753552e-08,3.80447418e-08,3.98141285e-08,4.15835151e-08
      ,4.33529017e-08,4.51222884e-08,4.68916750e-08,4.86610617e-08
      ,5.04304483e-08,5.21998350e-08,5.39692216e-08,5.57386082e-08
      ,5.75079949e-08,5.92773815e-08,6.10467682e-08,6.28161548e-08
      ,6.45855414e-08,6.63549281e-08,6.81243147e-08,6.98937014e-08
      ,7.16630880e-08,7.34324746e-08,7.52018613e-08,7.69712479e-08
      ,7.87406346e-08,8.05100212e-08,8.22794078e-08,8.40487945e-08
      ,8.58181811e-08,8.75875678e-08,8.93569544e-08,9.11263410e-08
      ,9.28957277e-08,9.46651143e-08,9.64345010e-08,9.82038876e-08
      ,9.99732742e-08,1.01742661e-07,1.03512048e-07,1.05281434e-07
      ,1.07050821e-07,1.08820207e-07,1.10589594e-07,1.12358981e-07
      ,1.14128367e-07,1.15897754e-07,1.17667141e-07,1.19436527e-07
      ,1.21205914e-07,1.22975301e-07,1.24744687e-07,1.26514074e-07
      ,1.28283460e-07,1.30052847e-07,1.31822234e-07,1.33591620e-07
      ,1.35361007e-07,1.37130394e-07,1.38899780e-07,1.40669167e-07
      ,1.42438554e-07,1.44207940e-07,1.45977327e-07,1.47746714e-07
      ,1.49516100e-07,1.51285487e-07,1.53054873e-07,1.54824260e-07
      ,1.56593647e-07,1.58363033e-07,1.60132420e-07,1.61901807e-07
      ,1.63671193e-07,1.65440580e-07,1.67209967e-07,1.68979353e-07
      ,1.70748740e-07,1.72518126e-07,1.74287513e-07,1.76056900e-07
      ,1.77826286e-07],
      
      "psd_y":[3.013e+03,1.812e+03,1.235e+03,8.650e+02,4.550e+02,2.780e+02,1.930e+02
      ,1.380e+02,7.800e+01,6.600e+01,6.100e+01,4.900e+01,4.500e+01,3.300e+01
      ,3.400e+01,3.200e+01,2.700e+01,2.600e+01,2.600e+01,3.800e+01,1.900e+01
      ,1.800e+01,8.000e+00,1.100e+01,1.000e+01,6.000e+00,4.000e+00,6.000e+00
      ,5.000e+00,7.000e+00,5.000e+00,3.000e+00,4.000e+00,7.000e+00,3.000e+00
      ,2.000e+00,2.000e+00,4.000e+00,2.000e+00,2.000e+00,0.000e+00,0.000e+00
      ,1.000e+00,1.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,1.000e+00,2.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,1.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,1.000e+00
      ,0.000e+00,1.000e+00],
      "fm_x":[-1.35815603e-09,-1.32407801e-09,-1.29000000e-09,-1.25592199e-09
      ,-1.22184397e-09,-1.18776596e-09,-1.15368794e-09,-1.11960993e-09
      ,-1.08553191e-09,-1.05145390e-09,-1.01737589e-09,-9.83297872e-10
      ,-9.49219858e-10,-9.15141844e-10,-8.81063830e-10,-8.46985816e-10
      ,-8.12907801e-10,-7.78829787e-10,-7.44751773e-10,-7.10673759e-10
      ,-6.76595745e-10,-6.42517730e-10,-6.08439716e-10,-5.74361702e-10
      ,-5.40283688e-10,-5.06205674e-10,-4.72127660e-10,-4.38049645e-10
      ,-4.03971631e-10,-3.69893617e-10,-3.35815603e-10,-3.01737589e-10
      ,-2.67659574e-10,-2.33581560e-10,-1.99503546e-10,-1.65425532e-10
      ,-1.31347518e-10,-9.72695035e-11,-6.31914894e-11,-2.91134752e-11
      ,4.96453901e-12,3.90425532e-11,7.31205674e-11,1.07198582e-10
      ,1.41276596e-10,1.75354610e-10,2.09432624e-10,2.43510638e-10
      ,2.77588652e-10,3.11666667e-10,3.45744681e-10,3.79822695e-10
      ,4.13900709e-10,4.47978723e-10,4.82056738e-10,5.16134752e-10
      ,5.50212766e-10,5.84290780e-10,6.18368794e-10,6.52446809e-10
      ,6.86524823e-10,7.20602837e-10,7.54680851e-10,7.88758865e-10
      ,8.22836879e-10,8.56914894e-10,8.90992908e-10,9.25070922e-10
      ,9.59148936e-10,9.93226950e-10,1.02730496e-09,1.06138298e-09
      ,1.09546099e-09,1.12953901e-09,1.16361702e-09,1.19769504e-09
      ,1.23177305e-09,1.26585106e-09,1.29992908e-09,1.33400709e-09
      ,1.36808511e-09,1.40216312e-09,1.43624113e-09,1.47031915e-09
      ,1.50439716e-09,1.53847518e-09,1.57255319e-09,1.60663121e-09
      ,1.64070922e-09,1.67478723e-09,1.70886525e-09,1.74294326e-09
      ,1.77702128e-09,1.81109929e-09,1.84517730e-09,1.87925532e-09
      ,1.91333333e-09,1.94741135e-09,1.98148936e-09,2.01556738e-09
      ,2.04964539e-09],
      "fm_y":[1.000e+00,0.000e+00,0.000e+00,0.000e+00,2.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,1.000e+00,1.000e+00,0.000e+00
      ,1.000e+00,2.000e+00,1.000e+00,4.000e+00,8.000e+00,1.100e+01,1.900e+01
      ,4.100e+01,4.600e+01,5.500e+01,4.700e+01,5.800e+01,6.800e+01,1.070e+02
      ,1.400e+02,2.580e+02,6.230e+02,9.820e+02,1.762e+03,1.869e+03,1.231e+03
      ,6.900e+02,2.600e+02,1.330e+02,5.700e+01,2.900e+01,2.300e+01,1.100e+01
      ,5.000e+00,9.000e+00,8.000e+00,1.600e+01,1.000e+01,1.000e+01,1.500e+01
      ,5.000e+00,8.000e+00,3.000e+00,4.000e+00,1.000e+00,0.000e+00,1.000e+00
      ,0.000e+00,0.000e+00,1.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,1.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,0.000e+00,0.000e+00,0.000e+00,1.000e+00,0.000e+00,0.000e+00
      ,0.000e+00,1.000e+00],
      "fsd_x":[1.20871540e-09,1.21574895e-09,1.22278251e-09,1.22981607e-09
      ,1.23684962e-09,1.24388318e-09,1.25091674e-09,1.25795029e-09
      ,1.26498385e-09,1.27201741e-09,1.27905096e-09,1.28608452e-09
      ,1.29311808e-09,1.30015163e-09,1.30718519e-09,1.31421875e-09
      ,1.32125230e-09,1.32828586e-09,1.33531942e-09,1.34235297e-09
      ,1.34938653e-09,1.35642009e-09,1.36345365e-09,1.37048720e-09
      ,1.37752076e-09,1.38455432e-09,1.39158787e-09,1.39862143e-09
      ,1.40565499e-09,1.41268854e-09,1.41972210e-09,1.42675566e-09
      ,1.43378921e-09,1.44082277e-09,1.44785633e-09,1.45488988e-09
      ,1.46192344e-09,1.46895700e-09,1.47599055e-09,1.48302411e-09
      ,1.49005767e-09,1.49709122e-09,1.50412478e-09,1.51115834e-09
      ,1.51819189e-09,1.52522545e-09,1.53225901e-09,1.53929256e-09
      ,1.54632612e-09,1.55335968e-09,1.56039324e-09,1.56742679e-09
      ,1.57446035e-09,1.58149391e-09,1.58852746e-09,1.59556102e-09
      ,1.60259458e-09,1.60962813e-09,1.61666169e-09,1.62369525e-09
      ,1.63072880e-09,1.63776236e-09,1.64479592e-09,1.65182947e-09
      ,1.65886303e-09,1.66589659e-09,1.67293014e-09,1.67996370e-09
      ,1.68699726e-09,1.69403081e-09,1.70106437e-09,1.70809793e-09
      ,1.71513148e-09,1.72216504e-09,1.72919860e-09,1.73623215e-09
      ,1.74326571e-09,1.75029927e-09,1.75733283e-09,1.76436638e-09
      ,1.77139994e-09,1.77843350e-09,1.78546705e-09,1.79250061e-09
      ,1.79953417e-09,1.80656772e-09,1.81360128e-09,1.82063484e-09
      ,1.82766839e-09,1.83470195e-09,1.84173551e-09,1.84876906e-09
      ,1.85580262e-09,1.86283618e-09,1.86986973e-09,1.87690329e-09
      ,1.88393685e-09,1.89097040e-09,1.89800396e-09,1.90503752e-09
      ,1.91207107e-09],
      "fsd_y":[1.,0.,0.,0.,0.,0.,0.,0.,0.,0.,1.,0.,0.,1.
      ,1.,2.,3.,0.,2.,1.,3.,3.,2.,5.,7.,6.,10.,17.
      ,8.,9.,15.,22.,24.,49.,32.,38.,55.,58.,80.,101.,111.,104.
      ,115.,158.,150.,171.,212.,204.,222.,262.,267.,288.,300.,292.,304.,324.
      ,325.,345.,292.,305.,272.,284.,240.,240.,237.,266.,221.,177.,173.,170.
      ,140.,144.,114.,106.,71.,81.,53.,57.,45.,41.,38.,32.,30.,15.
      ,12.,14.,13.,12.,5.,6.,6.,4.,3.,1.,2.,3.,1.,1.
      ,1.,2.],
      "dm_x":[-2.84697509e-11,-2.79003559e-11,-2.73309609e-11,-2.67615658e-11
      ,-2.61921708e-11,-2.56227758e-11,-2.50533808e-11,-2.44839858e-11
      ,-2.39145907e-11,-2.33451957e-11,-2.27758007e-11,-2.22064057e-11
      ,-2.16370107e-11,-2.10676157e-11,-2.04982206e-11,-1.99288256e-11
      ,-1.93594306e-11,-1.87900356e-11,-1.82206406e-11,-1.76512456e-11
      ,-1.70818505e-11,-1.65124555e-11,-1.59430605e-11,-1.53736655e-11
      ,-1.48042705e-11,-1.42348754e-11,-1.36654804e-11,-1.30960854e-11
      ,-1.25266904e-11,-1.19572954e-11,-1.13879004e-11,-1.08185053e-11
      ,-1.02491103e-11,-9.67971530e-12,-9.11032028e-12,-8.54092527e-12
      ,-7.97153025e-12,-7.40213523e-12,-6.83274021e-12,-6.26334520e-12
      ,-5.69395018e-12,-5.12455516e-12,-4.55516014e-12,-3.98576512e-12
      ,-3.41637011e-12,-2.84697509e-12,-2.27758007e-12,-1.70818505e-12
      ,-1.13879004e-12,-5.69395018e-13,3.78047389e-25,5.69395018e-13
      ,1.13879004e-12,1.70818505e-12,2.27758007e-12,2.84697509e-12
      ,3.41637011e-12,3.98576512e-12,4.55516014e-12,5.12455516e-12
      ,5.69395018e-12,6.26334520e-12,6.83274021e-12,7.40213523e-12
      ,7.97153025e-12,8.54092527e-12,9.11032028e-12,9.67971530e-12
      ,1.02491103e-11,1.08185053e-11,1.13879004e-11,1.19572954e-11
      ,1.25266904e-11,1.30960854e-11,1.36654804e-11,1.42348754e-11
      ,1.48042705e-11,1.53736655e-11,1.59430605e-11,1.65124555e-11
      ,1.70818505e-11,1.76512456e-11,1.82206406e-11,1.87900356e-11
      ,1.93594306e-11,1.99288256e-11,2.04982206e-11,2.10676157e-11
      ,2.16370107e-11,2.22064057e-11,2.27758007e-11,2.33451957e-11
      ,2.39145907e-11,2.44839858e-11,2.50533808e-11,2.56227758e-11
      ,2.61921708e-11,2.67615658e-11,2.73309609e-11,2.79003559e-11
      ,2.84697509e-11],
      "dm_y":[3.,0.,0.,0.,0.,0.,12.,0.,0.,0.,0.,0.
        ,48.,0.,0.,0.,0.,0.,136.,0.,0.,0.,0.,0.
        ,315.,32.,0.,0.,0.,0.,0.,660.,0.,0.,0.,0.
        ,0.,1088.,0.,0.,0.,0.,0.,1348.,0.,0.,0.,0.
        ,0.,1484.,26.,0.,0.,0.,0.,0.,1314.,0.,0.,0.
        ,0.,0.,1010.,0.,0.,0.,0.,0.,653.,0.,0.,0.
        ,0.,0.,304.,4.,0.,0.,0.,0.,0.,135.,0.,0.
        ,0.,0.,0.,47.,0.,0.,0.,0.,0.,17.,0.,0.
        ,0.,0.,0.,4.],
        "dsd_x":[2.06821890e-09,2.08043872e-09,2.09265853e-09,2.10487835e-09
        ,2.11709817e-09,2.12931799e-09,2.14153780e-09,2.15375762e-09
        ,2.16597744e-09,2.17819725e-09,2.19041707e-09,2.20263689e-09
        ,2.21485671e-09,2.22707652e-09,2.23929634e-09,2.25151616e-09
        ,2.26373597e-09,2.27595579e-09,2.28817561e-09,2.30039543e-09
        ,2.31261524e-09,2.32483506e-09,2.33705488e-09,2.34927470e-09
        ,2.36149451e-09,2.37371433e-09,2.38593415e-09,2.39815396e-09
        ,2.41037378e-09,2.42259360e-09,2.43481342e-09,2.44703323e-09
        ,2.45925305e-09,2.47147287e-09,2.48369269e-09,2.49591250e-09
        ,2.50813232e-09,2.52035214e-09,2.53257195e-09,2.54479177e-09
        ,2.55701159e-09,2.56923141e-09,2.58145122e-09,2.59367104e-09
        ,2.60589086e-09,2.61811067e-09,2.63033049e-09,2.64255031e-09
        ,2.65477013e-09,2.66698994e-09,2.67920976e-09,2.69142958e-09
        ,2.70364940e-09,2.71586921e-09,2.72808903e-09,2.74030885e-09
        ,2.75252866e-09,2.76474848e-09,2.77696830e-09,2.78918812e-09
        ,2.80140793e-09,2.81362775e-09,2.82584757e-09,2.83806739e-09
        ,2.85028720e-09,2.86250702e-09,2.87472684e-09,2.88694665e-09
        ,2.89916647e-09,2.91138629e-09,2.92360611e-09,2.93582592e-09
        ,2.94804574e-09,2.96026556e-09,2.97248538e-09,2.98470519e-09
        ,2.99692501e-09,3.00914483e-09,3.02136464e-09,3.03358446e-09
        ,3.04580428e-09,3.05802410e-09,3.07024391e-09,3.08246373e-09
        ,3.09468355e-09,3.10690336e-09,3.11912318e-09,3.13134300e-09
        ,3.14356282e-09,3.15578263e-09,3.16800245e-09,3.18022227e-09
        ,3.19244209e-09,3.20466190e-09,3.21688172e-09,3.22910154e-09
        ,3.24132135e-09,3.25354117e-09,3.26576099e-09,3.27798081e-09
        ,3.29020062e-09],
        "dsd_y":[1.,0.,0.,0.,0.,0.,0.,0.,1.,0.,1.,2.,2.,2.
        ,1.,2.,4.,1.,5.,4.,3.,11.,12.,15.,10.,11.,38.,27.
        ,46.,41.,58.,49.,58.,78.,81.,100.,125.,133.,143.,165.,157.,194.
        ,203.,209.,255.,242.,242.,264.,288.,264.,272.,305.,298.,289.,289.,260.
        ,247.,261.,270.,221.,226.,224.,222.,188.,179.,143.,150.,128.,111.,118.
        ,107.,104.,73.,65.,41.,44.,42.,41.,34.,17.,14.,25.,9.,12.
        ,11.,6.,10.,5.,6.,1.,6.,5.,2.,3.,1.,2.,2.,0.
        ,2.,1.],
        "ddm_x":[-4.28571429e-11,-4.19285714e-11,-4.10000000e-11,-4.00714286e-11
        ,-3.91428571e-11,-3.82142857e-11,-3.72857143e-11,-3.63571429e-11
        ,-3.54285714e-11,-3.45000000e-11,-3.35714286e-11,-3.26428571e-11
        ,-3.17142857e-11,-3.07857143e-11,-2.98571429e-11,-2.89285714e-11
        ,-2.80000000e-11,-2.70714286e-11,-2.61428571e-11,-2.52142857e-11
        ,-2.42857143e-11,-2.33571429e-11,-2.24285714e-11,-2.15000000e-11
        ,-2.05714286e-11,-1.96428571e-11,-1.87142857e-11,-1.77857143e-11
        ,-1.68571429e-11,-1.59285714e-11,-1.50000000e-11,-1.40714286e-11
        ,-1.31428571e-11,-1.22142857e-11,-1.12857143e-11,-1.03571429e-11
        ,-9.42857143e-12,-8.50000000e-12,-7.57142857e-12,-6.64285714e-12
        ,-5.71428571e-12,-4.78571429e-12,-3.85714286e-12,-2.92857143e-12
        ,-2.00000000e-12,-1.07142857e-12,-1.42857143e-13,7.85714286e-13
        ,1.71428571e-12,2.64285714e-12,3.57142857e-12,4.50000000e-12
        ,5.42857143e-12,6.35714286e-12,7.28571429e-12,8.21428571e-12
        ,9.14285714e-12,1.00714286e-11,1.10000000e-11,1.19285714e-11
        ,1.28571429e-11,1.37857143e-11,1.47142857e-11,1.56428571e-11
        ,1.65714286e-11,1.75000000e-11,1.84285714e-11,1.93571429e-11
        ,2.02857143e-11,2.12142857e-11,2.21428571e-11,2.30714286e-11
        ,2.40000000e-11,2.49285714e-11,2.58571429e-11,2.67857143e-11
        ,2.77142857e-11,2.86428571e-11,2.95714286e-11,3.05000000e-11
        ,3.14285714e-11,3.23571429e-11,3.32857143e-11,3.42142857e-11
        ,3.51428571e-11,3.60714286e-11,3.70000000e-11,3.79285714e-11
        ,3.88571429e-11,3.97857143e-11,4.07142857e-11,4.16428571e-11
        ,4.25714286e-11,4.35000000e-11,4.44285714e-11,4.53571429e-11
        ,4.62857143e-11,4.72142857e-11,4.81428571e-11,4.90714286e-11
        ,5.00000000e-11],
        "ddm_y":[11.,0.,0.,7.,0.,0.,0.,27.,0.,0.,0.,52.,0.,0.
        ,0.,118.,0.,0.,0.,206.,0.,0.,0.,275.,0.,0.,414.,0.
        ,0.,0.,552.,0.,0.,0.,632.,0.,0.,0.,763.,0.,0.,0.
        ,862.,0.,0.,0.,930.,0.,0.,168.,684.,0.,0.,749.,0.,0.
        ,0.,605.,0.,0.,0.,525.,0.,0.,0.,377.,0.,0.,0.,291.
        ,0.,0.,0.,182.,0.,0.,105.,0.,0.,0.,52.,0.,0.,0.
        ,28.,0.,0.,0.,15.,0.,0.,0.,6.,0.,0.,0.,3.,0.
        ,0.,1.],
        "ddsd_x":[3.75118349e-09,3.77537080e-09,3.79955811e-09,3.82374542e-09
        ,3.84793273e-09,3.87212004e-09,3.89630734e-09,3.92049465e-09
        ,3.94468196e-09,3.96886927e-09,3.99305658e-09,4.01724389e-09
        ,4.04143120e-09,4.06561851e-09,4.08980582e-09,4.11399313e-09
        ,4.13818044e-09,4.16236775e-09,4.18655506e-09,4.21074237e-09
        ,4.23492968e-09,4.25911699e-09,4.28330430e-09,4.30749161e-09
        ,4.33167892e-09,4.35586623e-09,4.38005354e-09,4.40424085e-09
        ,4.42842816e-09,4.45261547e-09,4.47680278e-09,4.50099009e-09
        ,4.52517740e-09,4.54936471e-09,4.57355202e-09,4.59773933e-09
        ,4.62192664e-09,4.64611395e-09,4.67030125e-09,4.69448856e-09
        ,4.71867587e-09,4.74286318e-09,4.76705049e-09,4.79123780e-09
        ,4.81542511e-09,4.83961242e-09,4.86379973e-09,4.88798704e-09
        ,4.91217435e-09,4.93636166e-09,4.96054897e-09,4.98473628e-09
        ,5.00892359e-09,5.03311090e-09,5.05729821e-09,5.08148552e-09
        ,5.10567283e-09,5.12986014e-09,5.15404745e-09,5.17823476e-09
        ,5.20242207e-09,5.22660938e-09,5.25079669e-09,5.27498400e-09
        ,5.29917131e-09,5.32335862e-09,5.34754593e-09,5.37173324e-09
        ,5.39592055e-09,5.42010786e-09,5.44429516e-09,5.46848247e-09
        ,5.49266978e-09,5.51685709e-09,5.54104440e-09,5.56523171e-09
        ,5.58941902e-09,5.61360633e-09,5.63779364e-09,5.66198095e-09
        ,5.68616826e-09,5.71035557e-09,5.73454288e-09,5.75873019e-09
        ,5.78291750e-09,5.80710481e-09,5.83129212e-09,5.85547943e-09
        ,5.87966674e-09,5.90385405e-09,5.92804136e-09,5.95222867e-09
        ,5.97641598e-09,6.00060329e-09,6.02479060e-09,6.04897791e-09
        ,6.07316522e-09,6.09735253e-09,6.12153984e-09,6.14572715e-09
        ,6.16991446e-09],
        "ddsd_y":[1.,0.,0.,0.,2.,4.,1.,2.,1.,2.,3.,5.,4.,10.
        ,11.,5.,16.,22.,32.,35.,31.,34.,50.,66.,67.,79.,93.,120.
        ,122.,138.,144.,170.,180.,191.,208.,247.,235.,241.,275.,274.,273.,298.
        ,280.,283.,289.,273.,309.,271.,259.,250.,262.,232.,221.,224.,200.,183.
        ,161.,150.,121.,123.,113.,98.,86.,91.,62.,77.,40.,45.,35.,21.
        ,24.,21.,23.,17.,18.,13.,9.,10.,4.,6.,11.,3.,2.,7.
        ,1.,3.,3.,5.,3.,0.,0.,1.,1.,0.,0.,2.,1.,0.
        ,0.,1.],
        "mean_vals":[6.838445229681979e-07,0.0,3.7679401568251793e-25,1.428571428577208e-11],
        "stdev_vals":[1.1513688436538453e-09,1.5710140376148834e-09,2.661174653007192e-09,4.774165169034748e-09],
        "data":[3.5, 3, 3.2, 3.1, 3.6, 3.9, 3.4, 3.4, 2.9, 3.1, 3.7, 3.4, 3, 3,
          4, 4.4, 3.9, 3.5, 3.8, 3.8, 3.4, 3.7, 3.6, 3.3, 3.4, 3, 3.4, 3.5, 3.4,
          3.2, 3.1, 3.4, 4.1, 4.2, 3.1, 3.2, 3.5, 3.6, 3, 3.4, 3.5, 2.3, 3.2, 3.5,
          3.8, 3, 3.8, 3.2, 3.7, 3.3, 3.2, 3.2, 3.1, 2.3, 2.8, 2.8, 3.3, 2.4, 2.9,
          2.7, 2, 3, 2.2, 2.9, 2.9, 3.1, 3, 2.7, 2.2, 2.5, 3.2, 2.8, 2.5, 2.8, 2.9,
          3, 2.8, 3, 2.9, 2.6, 2.4, 2.4, 2.7, 2.7, 3, 3.4, 3.1, 2.3, 3, 2.5, 2.6,
          3, 2.6, 2.3, 2.7, 3, 2.9, 2.9, 2.5, 2.8, 3.3, 2.7, 3, 2.9, 3, 3, 2.5, 2.9,
          2.5, 3.6, 3.2, 2.7, 3, 2.5, 2.8, 3.2, 3, 3.8, 2.6, 2.2, 3.2, 2.8, 2.8, 2.7,
          3.3, 3.2, 2.8, 3, 2.8, 3, 2.8, 3.8, 2.8, 2.8, 2.6, 3, 3.4, 3.1, 3, 3.1,
          3.1, 3.1, 2.7, 3.2, 3.3, 3, 2.5, 3, 3.4, 3]

};
  export default analyticalTimingData;