import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Login = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating fetching the token from an endpoint
    fetch('/api/user/get_token')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        setToken(data.token); // Set the token value received from the endpoint
        setLoading(false); // Set loading to false once token check is complete
      })
      .catch(error => {
        console.error('Error checking token:', error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Display a loading message while fetching token
  }

  if (token !== null) {
    // If token exists, redirect to another endpoint (change '/dashboard' to your desired endpoint)
    return <Redirect to="/darknet/dashboard" />;
  }

  // If token is null, display a login button (change 'your_saml_endpoint' to your actual endpoint)
  return (
    <div style={{ backgroundColor: 'lightblue', padding: '20px', textAlign: 'center' }}>
      <h1>Login Page</h1>
      <button onClick={() => window.location.href = '/api/user/?sso'}>Login via SAML</button>
      {/* You can add other login options or UI elements as needed */}
    </div>
  );
};

export default Login;