import React,  { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'; 
import './assets/home.css';  
import './assets/css/carousel.css'; 
import { Link } from "react-router-dom";
import YoutubeEmbed from "../sub_components/YoutubeEmbed/youtubeEmbed";

import darkNewImg from './assets/img/darknet.jpg';
 
import logoDss from './assets/img/logo-dss.png';
import logoPlanning from './assets/img/logo-planning.png';
import logoMite from './assets/img/logo-mite.png'

class Home extends Component {  
  constructor(props) {
    super(props);
    this.state = { 
      "videoWidth":639
    }
  }
  
  changeLinkDemo = (route) => {
    let path = window.location.href;

    if(path.split("/").indexOf("demo")!=-1){ //this is the handle of demo version
      //alert("/demo/darknet"+route);
     return "/demo/darknet"+route
    }else{
      // return "/darknet"+route
      return route
    }
 
    
  }

  render() {
  return (
    <div className="home-background"> 
 
    <div className="home-centercol row">

      <div className="home-videoblock row">
          <div className="home-video col-lg-8 col-sm-12" >
            <YoutubeEmbed                    
            embedId="Yho3ab_-ukI?autoplay=1&loop=1&rel=0&playlist=Yho3ab_-ukI"
            height="360px"
            width = {this.state.videoWidth+"px"}
            />
          </div>

          <div className="offset-xxs-1 offset-xs-1 offset-sm-1 offset-md-1 offset-lg-0 col-lg-4 col-sm-10 col-xs-10">
         
              <h2>About the Project</h2>
              <p className="home-text">The DarkNet project will develop and demonstrate a next-generation network
              architecture that ensures resilient, end-to-end or point to point communication 
              for the nation's electricity infrastructure.The network is purposefully architected to minimize external (e.g., Internet) 
              connectivity with the high-fidelity sensors possessing the physical multifunction 
              sensors to collectively train their measurements onto grid system states.
              </p>
         
          </div>
      </div>
 
        
        <div className="home-grey_body    
        offset-xs-1 offset-sm-1 offset-md-1 offset-lg-0                 
                    col-sm-11
                    col-md-10 
                    col-lg-12
                    col-xlg-4 
                    row">     
          <h5 className="col-sm-12 col-md-12 whiteText"> 
          Features</h5>       
          <div className="col-sm-12 col-md-12 col-lg-6"> 
          
          <Link to={this.changeLinkDemo("/darknet/darklogin")}>  
          <div className="home-thumbnail_tile row">
                                            
                  <div className="col-5 home-thumbnail_logoct">   
                  <h5 className="col-12 ">Sensor Dashboard</h5>                       
                  <img src={logoMite} 
                  className="home-thumbnail_logo border"></img>                  
                  </div>
                  <div className="home-text col-7 home-thumbnail_text">                  
                      Provides situational awareness of grid events using PTCT and ECP sensor data.</div>                    
               </div> 
          </Link>                              
          </div>

          {/* <div className="col-sm-12 col-md-12 col-lg-6">  */}
          {/* <Link to={this.changeLinkDemo("/castdashboard")}>  
                <div className="home-thumbnail_tile row">
                                            
                        <div className="col-5 home-thumbnail_logoct">   
                        <h5 className="col-12 ">CAST</h5>                       
                        <img src={logoCast} 
                        className="home-thumbnail_logo border"></img>
                        
                        
                        </div>
                        <div className="home-text col-7 home-thumbnail_text">
                        
                          The Center for Alternate Synchronization and Timing (CAST) at Oak Ridge National Laboratory (ORNL) is
                            focused on the design, development, evaluation,
                            and deployment of alternative timing services for the
                            nation’s power grid and other critical infrastructure.</div>                    
                </div>  
          </Link>                               */}
          </div>
           
      </div>

      {/* </div> <!--home --> */}

        
        
    </div>
    );
      }//render
};

export default Home;
