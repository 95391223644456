 import L from "leaflet";
 import $, { map } from "jquery";
import { nativeTouchData } from "react-dom/cjs/react-dom-test-utils.production.min";
import markerClusterGroup from 'leaflet.markercluster';

import sensorDashBoardEventLocation from "../data/event_locations"

import transparentMarker from '../img/transparent.png';
 
 export class evMapSetup {
    map="-1";
    featureHighlightStyle={

    };
 
    leafletLayers = {
        //sensorDashBoardEventLocation
        "sensorLocation":{     
            //"jsonUrl":"./data/event_locations.geojson",          
            "layerType":"circleMarker",  // regular
            "json":sensorDashBoardEventLocation,
            "style":                        
                   {
                        radius:6,
                        fillColor: '#ffbb00',
                        fill:1,
                        color:'#ff0000',
                        weight:3,
                        opacity: 1,
                        fillOpacity:1
                    },
           
            "fitBounds":true,
            "onEachFeature":function(feature, layer) {
                if (feature.properties) {               
                    let popupLabel = `Parking Area:`+feature.properties["PARKING_AR"]+`<br>`+
                                      `Spot Type:`+feature.properties["Layer"]+`<br>`;
                    layer.bindPopup(popupLabel);
                     
                  //"BUILDINGID": "0819", "ADA": null, "ADDRESS": null, "BUILDINGTY": "Trailer", "BUILDINGMA": "Brackett, Scott W", "CAPACITY": 0, "CONDITIONK": "Demolished", "DESCRIPTIO": null, "FACILITYKE": "1", "FACILITYNA": "ORNL Main Campus", "FLOORCOUNT": 1, "GROSSAREA": 200.0, "LONGNAME": "Farm Implement Storage Building", "MAINTBY": "West", "NETAREA": 180.44, "OCCUPANCY": 0, "OPERHOURSK": null, "OWNEDBY": "UT-Battelle", "REPLCOST": 0.0, "SHORTNAME": "0819", "URL": "http:\/\/facilities.ornl.gov\/fic\/FacilityProperties.aspx?facility_number=0819"
                if (feature.properties) {
                    let popupLabel = `Parking Type:`+feature.properties["Layer"]+`<br>`+
                                      `Parking Area:`+feature.properties["PARKING_AR"]+`<br>`+
                                      `Availbility:`+"unknown"+`<br>`;                                
                    layer.bindPopup(popupLabel);
                }                     
                var thisLayerObj=this;
                
                /*layer.on('mouseover', function () {
                    this.setStyle({                                      
                      "fillOpacity":1
                    });
                  });
                  layer.on('mouseout', function () {
                      this.setStyle({       
                        "fillOpacity":0.5                    
                      });
                    });
                layer.on('click', function () {
         
                      if(thisLayerObj.hasOwnProperty("highlightedFeature")){
                        thisLayerObj["highlightedFeature"].setStyle({
                             "opacity": 0,
                             "fillOpacity":0.5                             
                          });
                      } 
                        this.setStyle({    
                          "opacity": 1,
                        });
                        thisLayerObj["highlightedFeature"]=layer;
                        getIDandTypeInSearchBoxGenerateNavButton (layer);
                        getIDandTypeInSearchBox (layer);
                      });//end of layer
                    */ 
                }
            },
            "callbackfunc":function(inputsObj){
             
                //let bound = inputsObj["layerObj"]["layer"][0]["_layers"].getBounds();
                //console.log(inputsObj);
                //console.log(bound)

            },
            "autoCompleteField":{               
                "Location":[]
            }
    
        }
     }//end of layer

     leafletClickEvent = (feature, layer) => {
       
    }

   

     setLeafletLayerEvent= () =>{
      
        let matchVal = getName($("#navFeatureSearchBy").val());
        let layerVar = $("#navSearchBy").val();
        let layerName = "sensorLocation";
 
        let selectLayer  = this.leafletLayers[layerName];
        let localMap = this.map;
        let that=this;
        var selectedLayerList = [];
         //console.log("selectLayer",selectLayer)

        selectLayer["layer"].forEach(function(item,index){
      
            if(item.hasOwnProperty("_layers")){
       
            item.eachLayer(function (layer) {                       
                                  
                    if(layer.feature.properties[layerVar] == matchVal) {    
                      layer.setStyle({
                          // "weight":5,   
                           "opacity": 1, 
                           "fillOpacity": 1, 
                           //"weight":5                  
                        });
                      //selectedLayerList.push(layer);
                      let bound = layer.getBounds();   
                      console.log("bound",bound)
                      localMap.fitBounds(bound); 
                        if([''].indexOf(layerName)==-1){
                        localMap.fitBounds(bound);
                        triggerNavButton(bound);
                        }
                    }else{
                        //console.log(selectLayer["style"](layer.feature,layer))
                        let sty = selectLayer["style"](layer.feature,layer);
                        //layer.setStyle(sty);
                        //console.log(sty);
                        layer.setStyle({
                            "opacity":sty["opacity"],
                            "fillOpacity":sty["fillOpacity"]
                         });  
                         
                    }
                 
                
              });//end of for each
            }//end of check _layer
        })

          

        function getName(rawName){

            if(rawName.length==0){
                return " "
            }
            let array = rawName.split(" ");
        
            if(array[array.length-1].length==0){
                array.pop();
            } 
            if(array[0].length==0){
                
                array.shift();
            } 
            let name= array.join(" "); 
         
            return name;
        }//end of dunction
         
     }//end of setLeafletLayerEvent

     initDataField= () =>{
        for(let name in this.leafletLayers){
            let layerObj=this.leafletLayers[name];
            if(!this.leafletLayers[name].hasOwnProperty["json"]){
                let thisLayer = this.leafletLayers[name] ;
                $.getJSON( this.leafletLayers[name]["jsonUrl"], function( json ) {
                    thisLayer["json"]=json;

                    if(layerObj.hasOwnProperty("autoCompleteField")){

                        json["features"].forEach(function(item,index){ 
                            
                            for(let fieldName in layerObj["autoCompleteField"]){
                                let rawVal = item["properties"][fieldName] || "";
                                let val = rawVal.toString()
                                if(layerObj["autoCompleteField"][fieldName]
                                .indexOf(val)==-1){
                                    layerObj["autoCompleteField"][fieldName]
                                    .push(val);
                                }   
                            }        
                        });
        
                    
                    }//end of if
                });
            }    //end of initial  
               
        }

        $(document).on("click","#triggerNavgButton",function(evt) {
            let url = $("#triggerNavgButton").attr("navUrl");
            console.log($("#triggerNavgButton"),url); 
            window.open(url, '_blank');  
         });
    }
   
    initMap= () =>{
        console.log("this is called");
        var layerObjs = this.leafletLayers;
       if($("#map").find('.leaflet-pane').length==0){          
                
                var map = L.map('map').setView([35.93875234646035,-84.29699940781576], 3);
                let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                });
                let lite = L.tileLayer("http://{s}.sm.mapstack.stamen.com/(toner-lite,$fff[difference],$fff[@23],$fff[hsl-saturation@20])/{z}/{x}/{y}.png"); 
                let grey = L.tileLayer("http://{s}.sm.mapstack.stamen.com/(toner-background,$fff[difference],$fff[@23],$fff[hsl-saturation@20],toner-lines[destination-in])/{z}/{x}/{y}.png");
                lite.addTo(map);
                
                this.map = map; 
      
        // here is an empty event examples - setupLayers (layerObjs["evCharging"],map,function(){},{});    
        setupLayers (layerObjs["sensorLocation"],map,
                     function(){},
                     {"layerObj":layerObjs["sensorLocation"],"map":map});
        //leafletClusterMarkerLayer(layerObjs["parking"], map)

        setTimeout(function(){
            addremoveLayer(layerObjs);
        },500)
        

        $(document).on("change","#navSearchFor",function() {
            addremoveLayer(layerObjs);
         });

         function addremoveLayer(layerObjs){
            let layerName = "sensorLocation" //$("#navSearchFor").val();
        
            for(let eachLayer in layerObjs){
                 if(layerObjs[eachLayer].hasOwnProperty("layer")){

                    layerObjs[eachLayer]["layer"].forEach(function(item,index){
                        if(map.hasLayer(item)){ 
                            map.removeLayer(item);
                        }
                    })//end of if                     
                    
                    if(layerName==eachLayer){
                                       
                        layerObjs[eachLayer]["layer"].forEach(function(item,index){
                            if(!map.hasLayer(item)){   
                                map.addLayer(item);
                            }
                        })
                     }//end of if 
                 }
                                        
              
            }
        }

    } //end of if

        function setupLayers (layerObj,map,func,options){
            //console.log(layerObj)  
            /*console.log('json-L',layerObj,
                layerObj.hasOwnProperty["json"],
                layerObj["json"]
            )*/
                renderLayer(layerObj["json"],layerObj);
                func(options);
            
            if(layerObj.hasOwnProperty["json"]){
                renderLayer(layerObj["json"],layerObj);
                func(options);
            }else{
                $.getJSON( layerObj["jsonUrl"], function( json ) {
                    renderLayer(json,layerObj);
                    layerObj["json"]=json;
                    func(options);
                });//end of JsonGet
            }
            

             function renderLayer(json,layerObj){

               
                let layerType = layerObj["layerType"] || "regular";
                let layerTypeActions = {

                    "regular":function(json, layerObj){
                        return L.geoJSON(json, {
                            style: layerObj["style"],
                            onEachFeature:function(feature, layer) {
                                layerObj["onEachFeature"](feature, layer);
                            }
                        })
                    },
                    "circleMarker":function(json, layerObj){


                        /*return L.geoJson(json, {
                            pointToLayer: function (feature, latlng) {
                                //console.log("---",layerObj["style"]);
                                return L.circleMarker(latlng, layerObj["style"]);
                            }

                              .bindPopup("popup is working correctly")
                                    .bindTooltip("tooltipAnchor.x has no effect", { direction: 'top' })
                                    .addTo(map)

                        });*/

                        return L.geoJson(json, {
                            pointToLayer: function (feature, latlng) {
                                let label = String(feature.properties.Location)
                                return L.circleMarker(latlng, layerObj["style"])
                                .bindTooltip(label, 
                                    {permanent: true, opacity: 0.9, direction: String(feature.properties.ToolTipAnchor)})
                            }
                        }).addTo(map); 

                        
                    }//cm

                   
                }; 

                let layer = layerTypeActions[layerType](json, layerObj);

                if(!layerObj.hasOwnProperty("layer")){
                    layerObj["layer"]=[];
                }

                layerObj["layer"].push(layer);

                
            
                if(layerObj.hasOwnProperty("callbackfunc")){
                    layerObj["callbackfunc"](json);
                }
                if(layerObj.hasOwnProperty("fitBounds")){
                    if(layerObj["fitBounds"]==true){                   
                        let bd = layer.getBounds();
                        map.fitBounds(bd);
                    }                     
                }
                if(layerObj.hasOwnProperty("addToMapByDefault")){
                    if(layerObj["addToMapByDefault"]==true){                   
                        layer.addTo(map);
                    }                     
                }                
             }
        } //end of setupLayers

        /*
        L.marker([35.93092187143378, -84.30984905815112]).addTo(map)
            .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
            .openPopup();*/
    }// on init
 
 

}//end of class

function getIDandTypeInSearchBox (layer){
    //let matchVal = getName($("#navFeatureSearchBy").val());
    let layerVar = $("#navSearchBy").val();
    let layerName = $("#navSearchFor").val();
    let bounds = layer.getBounds();
    let matchVal = layer["feature"]["properties"][layerVar];
    $("#navFeatureSearchBy").val(matchVal);
    triggerNavButton(bounds);
}

function getIDandTypeInSearchBoxGenerateNavButton(layer){
    let matchFeature = layer["feature"];

}

function triggerNavButton(bounds){
    //console.log(bounds);
    var latlng = bounds.getCenter();
    

    let navUrl = `https://www.google.com/maps/dir//`
    +latlng["lat"]+`,`+latlng["lng"];

    $("#triggerNavGateContainer").empty();
    $("#triggerNavGateContainer").append(`
        <button id="triggerNavgButton"
        type="button"
        class="btn btn-info col-md-12"
        navUrl="`+navUrl+`"
        >Navigate</button>
    `);
    //console.log(bounds)    
 
}


function leafletClusterMarkerLayer(options){
    let layerObj = options["layerObj"];
    let map = options["map"];
    const average = list => list.reduce((prev, curr) => prev + curr) / list.length;

    let inputPoint = [];
    layerObj["json"]["features"].forEach(function(item,index){
       let x=[],y=[];
       //console.log(item["geometry"]["coordinates"],item["geometry"]["coordinates"].length);
       if(item["geometry"]["coordinates"].length!=0){
            item["geometry"]["coordinates"][0][0].forEach(function(item2,index2){            
                x.push(item2[1]);
                y.push(item2[0]);
            });
      
        let avx = average(x), avy = average(y);
        inputPoint.push([avx,avy,item["properties"]])
     
         }//end of if      
    });
    //layerObj["json"]

    var markers = L.markerClusterGroup({ chunkedLoading: true });		
    for (var i = 0; i < inputPoint.length; i++) {
        var a = inputPoint[i];
        var title = a[2];
        var transparentIcon = L.icon({
            iconUrl: transparentMarker,
            shadowUrl: transparentMarker,
        
            iconSize:     [38, 95], // size of the icon
            shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        var marker = L.marker(L.latLng(a[0], a[1]), {icon: transparentIcon}, { title: "title" });
        marker.bindPopup(title);
        markers.addLayer(marker);
    }
    //layerObj["layer"].push(markers);
    setTimeout(function(){
        console.log(layerObj["layer"]);
        layerObj["layer"].push(markers);
        map.addLayer(markers);
    },1000)
    
    
}

function  createOverviewTable(features,areaName,layer){
                    
    let summary = {}; 
 
    $(document).on("change","#navSearchFor",function() {
        $(".evTableItem").hide();
        let layername = $("#navSearchFor").val();
        $(".evTableItem-"+layername).show();
     });

    features.forEach(function(item,index){
        
        if(item["properties"].hasOwnProperty(areaName)){
            let nn = item["properties"][areaName];
            if(!summary.hasOwnProperty(nn)){
                summary[nn]={"total":0,"coords":[]}
            }
            summary[nn]["total"]+=1;
            
            if(item["geometry"]["coordinates"].length>0){
                summary[nn]["coords"].push(item["geometry"]["coordinates"][0][0][0]);
            }
           
             
        };
    }); //end of for Each

    //$("#evParkingTables").empty();
    for(let name in summary){
        let eachObj = summary[name];
       
        let x=[],y=[];
            //console.log(item["geometry"]["coordinates"],item["geometry"]["coordinates"].length);
        
        if(eachObj["coords"].length!=0){
            eachObj["coords"].forEach(function(item2,index2){            
                x.push(item2[1]);
                y.push(item2[0]);
            });
        const average = list => list.reduce((prev, curr) => prev + curr) / list.length;
        //console.log(x);
        //console.log(y);
        let avx = average(x).toFixed(6), avy = average(y).toFixed(6);                         
        let htmlTemplate = 
        `<tr  class='evTableItem-`+layer+` evTableItem'>
            <th scope="row">`+name+`</th>
            <td>`+eachObj["total"]+`</td>
            <td>`+
            Math.floor(eachObj["total"]*Math.random())+
            `</td>
            <td>`+`<button>`+avx+"-"+avy+`</button>`+`</td>
        </tr>`;
        $("#evParkingTables").append(htmlTemplate);
        $(".evTableItem").hide();
        $(".evTableItem-"+layer).show();
        }                    
    }//end of for  
}// end of createOverviewTable