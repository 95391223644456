import React, { Component  } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'; 
import './assets/css/dashboard.css';   
import './assets/css/dashboardLayout.css'; 

import $ from "jquery"
/*
import L from "leaflet"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'*/
import '../../../node_modules/leaflet/dist/leaflet.css'; 
import {evMapSetup} from './assets/module/evMapSetup';
import {stackChartSetup} from './assets/module/d3js3_stackedchart';

import '../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css'
import '../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css'

import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';

/*
import StepIndicator from 'react-native-step-indicator'; 
 */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Cookies from 'js-cookie';


const labels = ["Event Overview","Event Details","Analytics"];
const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize:30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#fe7013',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#fe7013',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#fe7013',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#fe7013',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#fe7013',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#fe7013'
}

// const cookie = Cookies.get('user', 'email', { domain: 'https://darknet.ornl.gov/web/cookie' })
// if (cookie) {
//   //pass
// } else {
//     window.location.href = 'https://darknet.ornl.gov/web/darklogin'; 
// }

class dashboard extends Component {

  evMapSetupInst = new evMapSetup();
  stackChartSetupInst = new stackChartSetup();
  constructor(props) {
    super(props);

    this.evMapSetupInst.initDataField();
    this.layerData = this.evMapSetupInst.leafletLayers;
  
    var date = new Date();
    var startDate = date.getTime() - (7 *24 *60 * 60 *1000)
    
    this.state = {     
      "searchFor":"sensorLocation", //"parling and EV"
      "searchBy":"Location",
      "searchID":"",
      "currentPosition": 0,
      "startDate":(new Date("2021-10-19")),
      // "endDate":this.startDate,
      //"startDate": date.setTime(startDate),
      "endDate":(new Date()),
      "aggregation":"hour"
    };
    

    this.eventPageChangeAgg = this.eventPageChangeAgg.bind(this);
    this.eventPageChangeSensor = this.eventPageChangeSensor.bind(this);
    

  }

  

  handleChangeSearchFor = (e) => {
    this.setState({ searchFor: e.target.value }); 
    let that=this;
    
        
    that.setState({ searchBy: $("#navSearchBy").val() }); 
    $("#navFeatureSearchBy").val(" "); 
 
    
  }
  
  handleChangeSearchBy = (e) => {
    $("#navFeatureSearchBy").val(" "); 
    this.setState({ searchBy: e.target.value });
    setTimeout(function(){      
      $("#navFeatureSearchBy").val(" ");  },100);    
  }

  handleFlowPositionBy = (e) => { 
    this.setState({ currentPosition: e.target.value });
 
  }
 
  handleClickInput = (e) => {
    alert();
  }
  
  handleSave = () => {
    // Navigate to /products
    this.props.history.replace("/products");
};


 onInit(){
       
    let that = this
    setTimeout(function(){   
      let evMapSetup1 = that.evMapSetupInst;     
      evMapSetup1.initMap();
      that.layerData = that.evMapSetupInst.leafletLayers;

      that.stackChartSetupInst.renderBarChart("",that.state.aggregation);

    }, 500);

    $(document).on("click",".toStep2",function(e) {
      $(".steps").hide();
      $(".step2").show();  
    });
    $(document).on("click",".toStep1",function(e) {
      $(".steps").hide();
      $(".step1").show();   
    })
  }

  getAutoComplete(){ 
    let val = []
    if(typeof(this.layerData)!="undefined"){
      val = this.layerData[this.state["searchFor"]]
    ["autoCompleteField"][this.state["searchBy"]]
    }     
    return val;
  }

  onPageChange(position){
    this.setState({currentPosition: position});
}

componentDidMount(){
  // Update status gauges every five seconds with last minute of data.
  //this.fetchData(); 
  this.onInit(); 


  $(".step2Charts_i_charts, .step2Charts_v_charts").hide();
  let dispVal = $("#sensorDashVorI").val();
  $("."+dispVal).show();  

}

eventPageChangeDate(date,field){
  //this.state.startDate(date);
  this.state[field]=date;
  this.setState({[field]: date});
  let startDayOneDayBefore = Number((this.state.startDate).getTime()*1000000)-86400000000000;
  let queryStringFilter = `?`+
  `start_timestamp=gte.`+startDayOneDayBefore+`&`+
  `end_timestamp=lte.`+(this.state.endDate).getTime()*1000000;

  //https://darknet.ornl.gov/api/event?sensor_id=eq.3&start_timestamp=gte.1634702400000000000&start_timestamp=lte.1634788800000000000
 
  this.stackChartSetupInst.renderBarChart(queryStringFilter,this.state.aggregation);
}

eventPageChangeSensor= (event) =>{
  let startDayOneDayBefore = Number((this.state.startDate).getTime()*1000000)-86400000000000;
  let queryStringFilter = `?`+
  `start_timestamp=gte.`+startDayOneDayBefore+`&`+
  `end_timestamp=lte.`+(this.state.endDate).getTime()*1000000;
  console.log("aggagg",this.state.aggregation);
  this.stackChartSetupInst.renderBarChart(queryStringFilter,this.state.aggregation);
}

eventPageChangeAgg= (event) =>{
  //console.log("event.target.value",event.target.value);
  //this.setState({aggregation: agg});
  this.setState({aggregation: event.target.value});
  let startDayOneDayBefore = Number((this.state.startDate).getTime()*1000000)-86400000000000;
  let queryStringFilter = `?`+
  `start_timestamp=gte.`+startDayOneDayBefore+`&`+
  `end_timestamp=lte.`+(this.state.endDate).getTime()*1000000;
  console.log("aggagg",this.state.aggregation);
  this.stackChartSetupInst.renderBarChart(queryStringFilter,event.target.value);
}

eventPageChangeStep2VorI= (event) =>{

  $(".step2Charts_i_charts, .step2Charts_v_charts").hide();
  let dispVal = $("#sensorDashVorI").val();
  $("."+dispVal).show();   
}

  render() {
    const optionList = {
      "searchBy":{
            /*"building":[
              { value: 'BUILDINGID', label: 'Building ID' },             
            ],*/
            "sensorLocation":[
              { value: 'all', label: 'All Location' },   
              { value: '1', label: 'PG&E (#1)' },  
              { value: '2', label: 'DECC (#2)' }, 
              { value: '3', label: 'EPB (#3)' }
              //"PG&E" "EPB" "DECC"
            ],       
        }
    };


    return  (

      <div className="row 
      sensorDash-centercol">
        <div className="step1 steps row navtool_container">
       
          <form 
          className="form-group row col-sm-12 col-md-12 
          col-md-12 sensorControl"> 
                   
          { 
            /* 
            <div className="form-group col-lg-6 
                          col-xl-4
                          col-md-6 col-sm-12">
          <StepIndicator 
                customStyles={customStyles}
                currentPosition="1"
                labels={labels}
                stepCount="3"   />  
                </div>
                */
          }    
          
          

          <div  className="form-group col-xl-2
                          col-lg-3 col-md-6 col-sm-6">                
                      <label htmlFor="navSearchBy" 
                      className="control-label">Filter Sensor By</label>
                      <select id="sensorDashBySensorLoc" 
                      
                      onChange={ this.eventPageChangeSensor } 
                      className="form-control">
                        {                  
                            optionList["searchBy"][this.state.searchFor].map(             
                              ({ value, label }) => <option key={value} value={value}>{label}</option>
                            )
                        }                          
                      </select>
                  </div>

                  <div  className="form-group col-lg-3 col-xl-2
                                    col-md-6 col-sm-6">                
                      <label htmlFor="navSearchBy" 
                      className="control-label">Temporal Aggregation</label>
                      <select id="temporalAggregation"                
                      onChange={this.eventPageChangeAgg} 
                      value = {this.state.aggregation}
                      className="form-control">
                      <option value="hour"> By Hour</option>   
                      <option value="day"> By Day</option>                                  
                      </select>
                  </div>   
              
                <div  className="form-group col-md-3 col-sm-6 col-lg-2 col-xl-2">
                <label htmlFor="navSearchBy" 
                    className="control-label">Start Date</label>
                    <DatePicker selected={this.state.startDate} 
                    maxDate = {this.state.endDate}                    
                    onChange={(date) => this.eventPageChangeDate(date,"startDate")} value={this.state.startDate} />
                </div>

                <div  className="form-group col-md-3 col-sm-6 col-lg-2  col-xl-2">
                <label htmlFor="navSearchBy" 
                    className="control-label ">End Date</label>                    
                    <DatePicker selected={this.state.endDate} 
                    className="sensorControl"
                    minDate = {this.state.startDate}     
                    onChange={(date) => this.eventPageChangeDate(date,"endDate")} />
                </div>                         
              </form>
                           
          <div className="
          col-md-12 col-sm-12 col-lg-12 border row ">
            
            <div className="col-md-7 col-sm-7 col-lg-7" id="stp1_stackChart"></div>

            <div className="
                  col-md-5 col-sm-5 col-lg-5 row map-container-wapper">
              <div className="
                  col-md-12 col-sm-12 col-lg-12 centerText">
                  Sensor Locations
                </div> 
              <div id="map"     
              className="            
              col-md-12 col-sm-12 col-lg-12 map-container"> 
              </div>
            </div>
         

          <div className="col-md-12 col-sm-12 col-lg-12 row sensor2nd">
          <div className="evTable col-md-7 col-sm-7 col-lg-7 border">   
              <div className="
                col-md-11 col-sm-11 col-lg-11 row ">
                Event Summary
              </div>   
                <table className="table table-striped evTable                          
                                    border">
                  <thead>
                    <tr>
                      <th scope="col">Event Group</th>                   
                      <th scope="col">Date</th>
                      <th scope="col">Count</th>               
                    </tr>
                  </thead>
                  <tbody id="evTable">
                    
                  </tbody>
                </table>

                <table className="table table-striped evTable                          
                                    border">
                  <thead>
                    <tr>
                      <th scope="col">Event Name</th>
                      <th scope="col">Event ID</th> 
                      <th scope="col">Type</th>   
                      <th scope="col">Explore</th>              
                    </tr>
                  </thead>
                  <tbody id="evTable2">                
                  </tbody>
                </table>
            </div>
            
            <div className="evTable col-md-5 col-sm-5 col-lg-5 samepagePlots"> 
            <div  className="form-group col-lg-12 col-xl-12
                                    col-md-2 col-sm-12">                
                      <label htmlFor="sensorDashVorI" 
                      className="control-label">Parameter</label>
                      <select id="sensorDashVorI" 
                      onChange={this.eventPageChangeStep2VorI}
                      className="form-control">
                      <option value="step2Charts_i_charts"> Current</option>   
                      <option value="step2Charts_v_charts"> Voltage</option>                                  
                      </select>
                  </div>  
            <div 
              id="step2Charts_i_e"
              className="col-md-12 col-sm-12 col-lg-12 step2Charts_i_charts">
                <br></br>
                <h3>Please Select Data (Current)</h3>
              </div>
              <div  
              id="step2Charts_v_e"
              className="col-md-12 col-sm-12 col-lg-12 step2Charts_v_charts">     
                <br></br>
                <h3>Please Select Data (Voltage)</h3>         
              </div>
            </div>

            </div>


            <div className="col-md-6 col-sm-12 col-lg-6 map-container">
                
            <form 
          className="form-group row col-sm-12 col-md-12 col-md-12">     
            
                  </form>
         

            </div>
          </div>

          <div  
            className="col-md-12 col-sm-12 col-lg-12 ">     
            <br></br>     
          </div>
    
      </div>
      <div className="step2 steps row col-md-12 col-sm-12 col-lg-12">
        <div className="row col-md-12 col-sm-12 col-lg-12">
        <div className="col-md-5 col-sm-5 col-lg-5">
          {
            /*
          <StepIndicator 
                customStyles={customStyles}
                currentPosition="2"
                labels={labels}
                stepCount="3"          
          /> */

          }
          </div>

                 

        <div  className="form-group col-lg-3 col-xl-3 md-offset-1
                                    col-md-3 col-sm-3">   
        
          <button type="button"                
              className="row btn btn-info form-control toStep1 col-md-12 col-sm-12 col-lg-12">Return to Overivew</button>
        </div>
          </div>

          <div className="row">
              <div 
              id="step2Charts_i_e_2"
              className="col-md-12 col-sm-12 col-lg-12 border step2Charts_i_charts">
     
              </div>
              <div  
              id="step2Charts_v_e_2"
              className="col-md-12 col-sm-12 col-lg-12 border step2Charts_v_charts">              
              </div>
              <div 
              id="step2Charts_i_p"
              className="col-md-12 col-sm-12 col-lg-12 border step2Charts_i_charts">
     
              </div>
              <div  
              id="step2Charts_v_p"
              className="col-md-12 col-sm-12 col-lg-12 border step2Charts_v_charts">              
              </div>

              <div 
                id="step2Charts_horizon1"
                className="row col-md-12 col-sm-12 col-lg-12 step2Charts_i_charts horizonChartsOffsets">
                </div>

                <div 
                id="step2Charts_horizon2"
                className="row col-md-12 col-sm-12 col-lg-12 step2Charts_v_charts horizonChartsOffsets">
                </div>
          </div>
          
 
      </div>
    </div>
    )  
    
  }; //end of render  
};
export default dashboard;


