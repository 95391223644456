const apiEvent = 
[{"id":1,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635640772770061056,"end_timestamp":1635640772786861056,"event_id":"2021-10-31_00-39-32-770_voltage-b_fast-transient","sensor_id":2}, 
 {"id":2,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642533672068608,"end_timestamp":1635642533688869120,"event_id":"2021-10-31_01-08-53-672_voltage-b_fast-transient","sensor_id":3}, 
 {"id":3,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635644426593857792,"end_timestamp":1635644426610657792,"event_id":"2021-10-31_01-40-26-593_voltage-b_fast-transient","sensor_id":2}, 
 {"id":4,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635644426593857792,"end_timestamp":1635644426610657792,"event_id":"2021-10-31_01-40-26-593_voltage-c_fast-transient","sensor_id":2}, 
 {"id":5,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635644930821457408,"end_timestamp":1635644930838257408,"event_id":"2021-10-31_01-48-50-821_voltage-b_fast-transient","sensor_id":2}, 
 {"id":6,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635645056616207104,"end_timestamp":1635645056633007104,"event_id":"2021-10-31_01-50-56-616_voltage-b_fast-transient","sensor_id":2}, 
 {"id":7,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635645056632856832,"end_timestamp":1635645056633007104,"event_id":"2021-10-31_01-50-56-616_voltage-c_fast-transient","sensor_id":2}, 
 {"id":8,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635647516622954752,"end_timestamp":1635647516639754752,"event_id":"2021-10-31_02-31-56-622_voltage-b_fast-transient","sensor_id":2}, 
 {"id":9,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635647516622954752,"end_timestamp":1635647516639754752,"event_id":"2021-10-31_02-31-56-622_voltage-c_fast-transient","sensor_id":2}, 
 {"id":10,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635648146604804608,"end_timestamp":1635648146621604352,"event_id":"2021-10-31_02-42-26-604_voltage-b_fast-transient","sensor_id":2}, 
 {"id":11,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642533672068608,"end_timestamp":1635642533688869120,"event_id":"2021-10-31_01-08-53-672_voltage-c_fast-transient","sensor_id":3}, 
 {"id":12,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635648146604804608,"end_timestamp":1635648146604954368,"event_id":"2021-10-31_02-42-26-604_voltage-c_fast-transient","sensor_id":2}, 
 {"id":13,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635649976638902784,"end_timestamp":1635649976655702528,"event_id":"2021-10-31_03-12-56-638_voltage-b_fast-transient","sensor_id":2}, 
 {"id":14,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635652244868200704,"end_timestamp":1635652244868350464,"event_id":"2021-10-31_03-50-44-868_voltage-b_fast-transient","sensor_id":2}, 
 {"id":15,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635653000840199936,"end_timestamp":1635653000856999936,"event_id":"2021-10-31_04-03-20-840_voltage-b_fast-transient","sensor_id":2}, 
 {"id":16,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635653000840199936,"end_timestamp":1635653000856999936,"event_id":"2021-10-31_04-03-20-840_voltage-c_fast-transient","sensor_id":2}, 
 {"id":17,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635655268822698240,"end_timestamp":1635655268839497984,"event_id":"2021-10-31_04-41-08-822_voltage-b_fast-transient","sensor_id":2}, 
 {"id":18,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635662450853691648,"end_timestamp":1635662450853841664,"event_id":"2021-10-31_06-40-50-853_voltage-b_fast-transient","sensor_id":2}, 
 {"id":19,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635667742872436992,"end_timestamp":1635667742889236992,"event_id":"2021-10-31_08-09-02-872_voltage-b_fast-transient","sensor_id":2}, 
 {"id":20,"group_name":"epc","channel":"voltage-a","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642659496218624,"end_timestamp":1635642659540868864,"event_id":"2021-10-31_01-10-59-496_voltage-a_fast-transient","sensor_id":3}, 
 {"id":21,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635667742889086720,"end_timestamp":1635667742889236992,"event_id":"2021-10-31_08-09-02-872_voltage-c_fast-transient","sensor_id":2}, 
 {"id":22,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635669506698435328,"end_timestamp":1635669506715235328,"event_id":"2021-10-31_08-38-26-698_voltage-b_fast-transient","sensor_id":2}, 
 {"id":23,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635669506698435328,"end_timestamp":1635669506715235328,"event_id":"2021-10-31_08-38-26-698_voltage-c_fast-transient","sensor_id":2}, 
 {"id":24,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635680594917575424,"end_timestamp":1635680594917725440,"event_id":"2021-10-31_11-43-14-917_voltage-b_fast-transient","sensor_id":2}, 
 {"id":25,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635680594934225664,"end_timestamp":1635680594934375680,"event_id":"2021-10-31_11-43-14-917_voltage-c_fast-transient","sensor_id":2}, 
 {"id":26,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635680846740575232,"end_timestamp":1635680846757375232,"event_id":"2021-10-31_11-47-26-740_voltage-b_fast-transient","sensor_id":2}, 
 {"id":27,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635682610935073792,"end_timestamp":1635682610951873792,"event_id":"2021-10-31_12-16-50-935_voltage-b_fast-transient","sensor_id":2}, 
 {"id":28,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635682610935073792,"end_timestamp":1635682610951873792,"event_id":"2021-10-31_12-16-50-935_voltage-c_fast-transient","sensor_id":2}, 
 {"id":29,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642659496218624,"end_timestamp":1635642659540868864,"event_id":"2021-10-31_01-10-59-496_voltage-b_fast-transient","sensor_id":3}, 
 {"id":30,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635685130942971648,"end_timestamp":1635685130959771648,"event_id":"2021-10-31_12-58-50-942_voltage-b_fast-transient","sensor_id":2}, 
 {"id":31,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635685130942971648,"end_timestamp":1635685130959771648,"event_id":"2021-10-31_12-58-50-942_voltage-c_fast-transient","sensor_id":2}, 
 {"id":32,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635685634945320960,"end_timestamp":1635685634945471232,"event_id":"2021-10-31_13-07-14-945_voltage-b_fast-transient","sensor_id":2}, 
 {"id":33,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635686768974570240,"end_timestamp":1635686768974720000,"event_id":"2021-10-31_13-26-08-974_voltage-b_fast-transient","sensor_id":2}, 
 {"id":34,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635687272948269824,"end_timestamp":1635687272948419584,"event_id":"2021-10-31_13-34-32-948_voltage-b_fast-transient","sensor_id":2}, 
 {"id":35,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635688406763768576,"end_timestamp":1635688406763918848,"event_id":"2021-10-31_13-53-26-763_voltage-b_fast-transient","sensor_id":2}, 
 {"id":36,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642659496218624,"end_timestamp":1635642659540868864,"event_id":"2021-10-31_01-10-59-496_voltage-c_fast-transient","sensor_id":3}, 
 {"id":37,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635690926779416576,"end_timestamp":1635690926796216576,"event_id":"2021-10-31_14-35-26-779_voltage-b_fast-transient","sensor_id":2}, 
 {"id":38,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635690926779416576,"end_timestamp":1635690926796216576,"event_id":"2021-10-31_14-35-26-779_voltage-c_fast-transient","sensor_id":2}, 
 {"id":39,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635691304958516224,"end_timestamp":1635691304975315968,"event_id":"2021-10-31_14-41-44-958_voltage-b_fast-transient","sensor_id":2}, 
 {"id":40,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635695912992412160,"end_timestamp":1635695912992561920,"event_id":"2021-10-31_15-58-32-992_voltage-b_fast-transient","sensor_id":2}, 
 {"id":41,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635695913009061888,"end_timestamp":1635695913009211904,"event_id":"2021-10-31_15-58-33-009_voltage-b_fast-transient","sensor_id":2}, 
 {"id":42,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635696416794011648,"end_timestamp":1635696416810811392,"event_id":"2021-10-31_16-06-56-794_voltage-b_fast-transient","sensor_id":2}, 
 {"id":43,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635697551035460352,"end_timestamp":1635697551052260352,"event_id":"2021-10-31_16-25-51-035_voltage-b_fast-transient","sensor_id":2}, 
 {"id":44,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635697929033960448,"end_timestamp":1635697929050760192,"event_id":"2021-10-31_16-32-09-033_voltage-b_fast-transient","sensor_id":2}, 
 {"id":45,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642722684668416,"end_timestamp":1635642722701468416,"event_id":"2021-10-31_01-12-02-684_voltage-c_fast-transient","sensor_id":3}, 
 {"id":46,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635698432999109632,"end_timestamp":1635698432999259904,"event_id":"2021-10-31_16-40-32-999_voltage-b_fast-transient","sensor_id":2}, 
 {"id":47,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635698432999109632,"end_timestamp":1635698432999259904,"event_id":"2021-10-31_16-40-32-999_voltage-c_fast-transient","sensor_id":2}, 
 {"id":48,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635698433015759872,"end_timestamp":1635698433015909632,"event_id":"2021-10-31_16-40-33-015_voltage-b_fast-transient","sensor_id":2}, 
 {"id":49,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635698433015759872,"end_timestamp":1635698433015909632,"event_id":"2021-10-31_16-40-33-015_voltage-c_fast-transient","sensor_id":2}, 
 {"id":50,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635699315016259072,"end_timestamp":1635699315016408832,"event_id":"2021-10-31_16-55-15-016_voltage-b_fast-transient","sensor_id":2}, 
 {"id":51,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635699944988808704,"end_timestamp":1635699944988958464,"event_id":"2021-10-31_17-05-44-988_voltage-b_fast-transient","sensor_id":2}, 
 {"id":52,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635699945005458432,"end_timestamp":1635699945005608448,"event_id":"2021-10-31_17-05-45-005_voltage-b_fast-transient","sensor_id":2}, 
 {"id":53,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642785668368384,"end_timestamp":1635642785685168640,"event_id":"2021-10-31_01-13-05-668_voltage-b_fast-transient","sensor_id":3}, 
 {"id":54,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635700071049758464,"end_timestamp":1635700071066558464,"event_id":"2021-10-31_17-07-51-049_voltage-b_fast-transient","sensor_id":2}, 
 {"id":55,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635700196791158272,"end_timestamp":1635700196807958016,"event_id":"2021-10-31_17-09-56-791_voltage-b_fast-transient","sensor_id":2}, 
 {"id":56,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635701204991907072,"end_timestamp":1635701204992057344,"event_id":"2021-10-31_17-26-44-991_voltage-b_fast-transient","sensor_id":2}, 
 {"id":57,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635701205008557312,"end_timestamp":1635701205008707584,"event_id":"2021-10-31_17-26-45-008_voltage-b_fast-transient","sensor_id":2}, 
 {"id":58,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635702086791956480,"end_timestamp":1635702086808756224,"event_id":"2021-10-31_17-41-26-791_voltage-b_fast-transient","sensor_id":2}, 
 {"id":59,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635702086808606720,"end_timestamp":1635702086808756224,"event_id":"2021-10-31_17-41-26-791_voltage-c_fast-transient","sensor_id":2}, 
 {"id":61,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635703976822104832,"end_timestamp":1635703976838904832,"event_id":"2021-10-31_18-12-56-822_voltage-b_fast-transient","sensor_id":2}, 
 {"id":62,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635703976838754560,"end_timestamp":1635703976838904832,"event_id":"2021-10-31_18-12-56-822_voltage-c_fast-transient","sensor_id":2}, 
 {"id":63,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635705236825853952,"end_timestamp":1635705236842653696,"event_id":"2021-10-31_18-33-56-825_voltage-b_fast-transient","sensor_id":2}, 
 {"id":64,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635705236825853952,"end_timestamp":1635705236826003456,"event_id":"2021-10-31_18-33-56-825_voltage-c_fast-transient","sensor_id":2}, 
 {"id":65,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635705741016703232,"end_timestamp":1635705741033502976,"event_id":"2021-10-31_18-42-21-016_voltage-b_fast-transient","sensor_id":2}, 
 {"id":66,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635705741016703232,"end_timestamp":1635705741033502976,"event_id":"2021-10-31_18-42-21-016_voltage-c_fast-transient","sensor_id":2}, 
 {"id":67,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635707756829601280,"end_timestamp":1635707756846401792,"event_id":"2021-10-31_19-15-56-829_voltage-b_fast-transient","sensor_id":2}, 
 {"id":68,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635709016829450496,"end_timestamp":1635709016846250496,"event_id":"2021-10-31_19-36-56-829_voltage-b_fast-transient","sensor_id":2}, 
 {"id":70,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635709646818999808,"end_timestamp":1635709646835799808,"event_id":"2021-10-31_19-47-26-818_voltage-b_fast-transient","sensor_id":2}, 
 {"id":71,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635709646835650048,"end_timestamp":1635709646835799808,"event_id":"2021-10-31_19-47-26-818_voltage-c_fast-transient","sensor_id":2}, 
 {"id":72,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635713426834396416,"end_timestamp":1635713426851196416,"event_id":"2021-10-31_20-50-26-834_voltage-b_fast-transient","sensor_id":2}, 
 {"id":73,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635713426834396416,"end_timestamp":1635713426851196416,"event_id":"2021-10-31_20-50-26-834_voltage-c_fast-transient","sensor_id":2}, 
 {"id":74,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635715316861044992,"end_timestamp":1635715316877844736,"event_id":"2021-10-31_21-21-56-861_voltage-b_fast-transient","sensor_id":2}, 
 {"id":75,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635716199038194176,"end_timestamp":1635716199054994176,"event_id":"2021-10-31_21-36-39-038_voltage-b_fast-transient","sensor_id":2}, 
 {"id":76,"group_name":"epc","channel":"voltage-a","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635716325037693952,"end_timestamp":1635716325054493952,"event_id":"2021-10-31_21-38-45-037_voltage-a_fast-transient","sensor_id":2}, 
 {"id":77,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635716325037693952,"end_timestamp":1635716325054493952,"event_id":"2021-10-31_21-38-45-037_voltage-b_fast-transient","sensor_id":2}, 
 {"id":60,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642785668368384,"end_timestamp":1635642785713868544,"event_id":"2021-10-31_01-13-05-668_voltage-c_fast-transient","sensor_id":3}, 
 {"id":69,"group_name":"epc","channel":"voltage-a","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642911705418496,"end_timestamp":1635642911722218496,"event_id":"2021-10-31_01-15-11-676_voltage-a_fast-transient","sensor_id":3}, 
 {"id":78,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642911676768256,"end_timestamp":1635642911722218496,"event_id":"2021-10-31_01-15-11-676_voltage-b_fast-transient","sensor_id":3}, 
 {"id":79,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635716325037693952,"end_timestamp":1635716325054493952,"event_id":"2021-10-31_21-38-45-037_voltage-c_fast-transient","sensor_id":2}, 
 {"id":80,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635717836865142272,"end_timestamp":1635717836881942784,"event_id":"2021-10-31_22-03-56-865_voltage-b_fast-transient","sensor_id":2}, 
 {"id":81,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635717963082092288,"end_timestamp":1635717963082242560,"event_id":"2021-10-31_22-06-03-082_voltage-b_fast-transient","sensor_id":2}, 
 {"id":82,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635718466850842112,"end_timestamp":1635718466867641856,"event_id":"2021-10-31_22-14-26-850_voltage-b_fast-transient","sensor_id":2}, 
 {"id":83,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635720356856990464,"end_timestamp":1635720356873790208,"event_id":"2021-10-31_22-45-56-856_voltage-b_fast-transient","sensor_id":2}, 
 {"id":84,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635642911676768256,"end_timestamp":1635642911722218496,"event_id":"2021-10-31_01-15-11-676_voltage-c_fast-transient","sensor_id":3}, 
 {"id":85,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635721616881889280,"end_timestamp":1635721616898689280,"event_id":"2021-10-31_23-06-56-881_voltage-b_fast-transient","sensor_id":2}, 
 {"id":86,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635722246867588608,"end_timestamp":1635722246884388352,"event_id":"2021-10-31_23-17-26-867_voltage-b_fast-transient","sensor_id":2}, 
 {"id":87,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635724136890386944,"end_timestamp":1635724136907186944,"event_id":"2021-10-31_23-48-56-890_voltage-b_fast-transient","sensor_id":2}, 
 {"id":88,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635724389127136768,"end_timestamp":1635724389143936512,"event_id":"2021-10-31_23-53-09-127_voltage-b_fast-transient","sensor_id":2}, 
 {"id":94,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643289497218048,"end_timestamp":1635643289541868288,"event_id":"2021-10-31_01-21-29-497_voltage-c_fast-transient","sensor_id":3}, 
 {"id":102,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643415673468160,"end_timestamp":1635643415690268160,"event_id":"2021-10-31_01-23-35-673_voltage-c_fast-transient","sensor_id":3}, 
 {"id":111,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643541686018048,"end_timestamp":1635643541702817792,"event_id":"2021-10-31_01-25-41-686_voltage-b_fast-transient","sensor_id":3}, 
 {"id":118,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643541686018048,"end_timestamp":1635643541702817792,"event_id":"2021-10-31_01-25-41-686_voltage-c_fast-transient","sensor_id":3}, 
 {"id":127,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643667669717760,"end_timestamp":1635643667686517760,"event_id":"2021-10-31_01-27-47-669_voltage-c_fast-transient","sensor_id":3}, 
 {"id":136,"group_name":"epc","channel":"voltage-a","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643919506417664,"end_timestamp":1635643919523217664,"event_id":"2021-10-31_01-31-59-506_voltage-a_fast-transient","sensor_id":3}, 
 {"id":144,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643919506417664,"end_timestamp":1635643919551067392,"event_id":"2021-10-31_01-31-59-506_voltage-b_fast-transient","sensor_id":3}, 
 {"id":151,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635643919506417664,"end_timestamp":1635643919551067392,"event_id":"2021-10-31_01-31-59-506_voltage-c_fast-transient","sensor_id":3}, 
 {"id":89,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635554894514987008,"end_timestamp":1635554894531787008,"event_id":"2021-10-30_00-48-14-514_voltage-b_fast-transient","sensor_id":2}, 
 {"id":90,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635558800533883648,"end_timestamp":1635558800534033408,"event_id":"2021-10-30_01-53-20-533_voltage-c_fast-transient","sensor_id":2}, 
 {"id":91,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635561320495681280,"end_timestamp":1635561320512481280,"event_id":"2021-10-30_02-35-20-495_voltage-b_fast-transient","sensor_id":2}, 
 {"id":92,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635561320495681280,"end_timestamp":1635561320512481280,"event_id":"2021-10-30_02-35-20-495_voltage-c_fast-transient","sensor_id":2}, 
 {"id":93,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635562454572730112,"end_timestamp":1635562454589530112,"event_id":"2021-10-30_02-54-14-572_voltage-b_fast-transient","sensor_id":2}, 
 {"id":95,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635562454572730112,"end_timestamp":1635562454589530112,"event_id":"2021-10-30_02-54-14-572_voltage-c_fast-transient","sensor_id":2}, 
 {"id":96,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563210512129280,"end_timestamp":1635563210528929792,"event_id":"2021-10-30_03-06-50-512_voltage-b_fast-transient","sensor_id":2}, 
 {"id":97,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563210512129280,"end_timestamp":1635563210528929792,"event_id":"2021-10-30_03-06-50-512_voltage-c_fast-transient","sensor_id":2}, 
 {"id":98,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563654570079232,"end_timestamp":1635563654586879232,"event_id":"2021-10-30_03-14-14-570_voltage-b_fast-transient","sensor_id":2}, 
 {"id":99,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563654570079232,"end_timestamp":1635563654586879232,"event_id":"2021-10-30_03-14-14-570_voltage-c_fast-transient","sensor_id":2}, 
 {"id":100,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563780504078848,"end_timestamp":1635563780520879360,"event_id":"2021-10-30_03-16-20-504_voltage-b_fast-transient","sensor_id":2}, 
 {"id":101,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635563780504078848,"end_timestamp":1635563780504229120,"event_id":"2021-10-30_03-16-20-504_voltage-c_fast-transient","sensor_id":2}, 
 {"id":103,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635564032535728896,"end_timestamp":1635564032552528896,"event_id":"2021-10-30_03-20-32-535_voltage-b_fast-transient","sensor_id":2}, 
 {"id":104,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635564032535728896,"end_timestamp":1635564032552528896,"event_id":"2021-10-30_03-20-32-535_voltage-c_fast-transient","sensor_id":2}, 
 {"id":105,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635565922560677120,"end_timestamp":1635565922560827136,"event_id":"2021-10-30_03-52-02-560_voltage-c_fast-transient","sensor_id":2}, 
 {"id":106,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635567182563576320,"end_timestamp":1635567182563725824,"event_id":"2021-10-30_04-13-02-563_voltage-b_fast-transient","sensor_id":2}, 
 {"id":107,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635570836356672768,"end_timestamp":1635570836373472768,"event_id":"2021-10-30_05-13-56-356_voltage-b_fast-transient","sensor_id":2}, 
 {"id":108,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635571466342121984,"end_timestamp":1635571466358921984,"event_id":"2021-10-30_05-24-26-342_voltage-b_fast-transient","sensor_id":2}, 
 {"id":109,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635571466342121984,"end_timestamp":1635571466358921984,"event_id":"2021-10-30_05-24-26-342_voltage-c_fast-transient","sensor_id":2}, 
 {"id":110,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635585896389709568,"end_timestamp":1635585896389859584,"event_id":"2021-10-30_09-24-56-389_voltage-b_fast-transient","sensor_id":2}, 
 {"id":112,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635590306402355712,"end_timestamp":1635590306419155712,"event_id":"2021-10-30_10-38-26-402_voltage-b_fast-transient","sensor_id":2}, 
 {"id":113,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635590306419005440,"end_timestamp":1635590306419155712,"event_id":"2021-10-30_10-38-26-402_voltage-c_fast-transient","sensor_id":2}, 
 {"id":114,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635592700598503680,"end_timestamp":1635592700615303424,"event_id":"2021-10-30_11-18-20-598_voltage-b_fast-transient","sensor_id":2}, 
 {"id":115,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635592952622153216,"end_timestamp":1635592952638953216,"event_id":"2021-10-30_11-22-32-622_voltage-b_fast-transient","sensor_id":2}, 
 {"id":116,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635592952638802944,"end_timestamp":1635592952638953216,"event_id":"2021-10-30_11-22-32-622_voltage-c_fast-transient","sensor_id":2}, 
 {"id":117,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635598874624747776,"end_timestamp":1635598874624898048,"event_id":"2021-10-30_13-01-14-624_voltage-b_fast-transient","sensor_id":2}, 
 {"id":119,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635599000648797952,"end_timestamp":1635599000665597952,"event_id":"2021-10-30_13-03-20-648_voltage-b_fast-transient","sensor_id":2}, 
 {"id":120,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635599000665447680,"end_timestamp":1635599000665597952,"event_id":"2021-10-30_13-03-20-648_voltage-c_fast-transient","sensor_id":2}, 
 {"id":121,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635600512646996480,"end_timestamp":1635600512663796480,"event_id":"2021-10-30_13-28-32-646_voltage-b_fast-transient","sensor_id":2}, 
 {"id":122,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635600638634196224,"end_timestamp":1635600638650996224,"event_id":"2021-10-30_13-30-38-634_voltage-b_fast-transient","sensor_id":2}, 
 {"id":123,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635600638634196224,"end_timestamp":1635600638650996224,"event_id":"2021-10-30_13-30-38-634_voltage-c_fast-transient","sensor_id":2}, 
 {"id":124,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635600764637796352,"end_timestamp":1635600764654596352,"event_id":"2021-10-30_13-32-44-637_voltage-b_fast-transient","sensor_id":2}, 
 {"id":125,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635600764654446080,"end_timestamp":1635600764654596352,"event_id":"2021-10-30_13-32-44-637_voltage-c_fast-transient","sensor_id":2}, 
 {"id":126,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635605300671842048,"end_timestamp":1635605300688642048,"event_id":"2021-10-30_14-48-20-671_voltage-b_fast-transient","sensor_id":2}, 
 {"id":128,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635605300671842048,"end_timestamp":1635605300688642048,"event_id":"2021-10-30_14-48-20-671_voltage-c_fast-transient","sensor_id":2}, 
 {"id":129,"group_name":"ptct","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635605414527791872,"end_timestamp":1635605414580091904,"event_id":"2021-10-30_14-50-14-527_voltage-b_fast-transient","sensor_id":2}, 
 {"id":130,"group_name":"ptct","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635605414527791872,"end_timestamp":1635605414535592192,"event_id":"2021-10-30_14-50-14-527_voltage-c_fast-transient","sensor_id":2}, 
 {"id":131,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635606308676191232,"end_timestamp":1635606308692991232,"event_id":"2021-10-30_15-05-08-676_voltage-b_fast-transient","sensor_id":2}, 
 {"id":132,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635606308676191232,"end_timestamp":1635606308692991232,"event_id":"2021-10-30_15-05-08-676_voltage-c_fast-transient","sensor_id":2}, 
 {"id":133,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635607316496390400,"end_timestamp":1635607316496540672,"event_id":"2021-10-30_15-21-56-496_voltage-b_fast-transient","sensor_id":2}, 
 {"id":134,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635608324696939520,"end_timestamp":1635608324713739520,"event_id":"2021-10-30_15-38-44-696_voltage-b_fast-transient","sensor_id":2}, 
 {"id":135,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635608324696939520,"end_timestamp":1635608324713739520,"event_id":"2021-10-30_15-38-44-696_voltage-c_fast-transient","sensor_id":2}, 
 {"id":137,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635609206467938816,"end_timestamp":1635609206484738816,"event_id":"2021-10-30_15-53-26-467_voltage-b_fast-transient","sensor_id":2}, 
 {"id":138,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635610466466787840,"end_timestamp":1635610466483587840,"event_id":"2021-10-30_16-14-26-466_voltage-b_fast-transient","sensor_id":2}, 
 {"id":139,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635610466466787840,"end_timestamp":1635610466466937600,"event_id":"2021-10-30_16-14-26-466_voltage-c_fast-transient","sensor_id":2}, 
 {"id":140,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635610718670587648,"end_timestamp":1635610718687387392,"event_id":"2021-10-30_16-18-38-670_voltage-b_fast-transient","sensor_id":2}, 
 {"id":141,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635610718670587648,"end_timestamp":1635610718670737152,"event_id":"2021-10-30_16-18-38-670_voltage-c_fast-transient","sensor_id":2}, 
 {"id":142,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635611474671587072,"end_timestamp":1635611474688386816,"event_id":"2021-10-30_16-31-14-671_voltage-b_fast-transient","sensor_id":2}, 
 {"id":143,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635611474688236800,"end_timestamp":1635611474688386816,"event_id":"2021-10-30_16-31-14-671_voltage-c_fast-transient","sensor_id":2}, 
 {"id":145,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635614246485084416,"end_timestamp":1635614246501884416,"event_id":"2021-10-30_17-17-26-485_voltage-b_fast-transient","sensor_id":2}, 
 {"id":146,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635614246485084416,"end_timestamp":1635614246501884416,"event_id":"2021-10-30_17-17-26-485_voltage-c_fast-transient","sensor_id":2}, 
 {"id":147,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635614372693534464,"end_timestamp":1635614372710334208,"event_id":"2021-10-30_17-19-32-693_voltage-b_fast-transient","sensor_id":2}, 
 {"id":148,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635615506488933120,"end_timestamp":1635615506505733120,"event_id":"2021-10-30_17-38-26-488_voltage-b_fast-transient","sensor_id":2}, 
 {"id":149,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635616262732032768,"end_timestamp":1635616262748832768,"event_id":"2021-10-30_17-51-02-732_voltage-b_fast-transient","sensor_id":2}, 
 {"id":150,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635618026496481024,"end_timestamp":1635618026513280768,"event_id":"2021-10-30_18-20-26-496_voltage-b_fast-transient","sensor_id":2}, 
 {"id":152,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635618026496481024,"end_timestamp":1635618026513280768,"event_id":"2021-10-30_18-20-26-496_voltage-c_fast-transient","sensor_id":2}, 
 {"id":153,"group_name":"epc","channel":"voltage-b","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635620042743479296,"end_timestamp":1635620042760279296,"event_id":"2021-10-30_18-54-02-743_voltage-b_fast-transient","sensor_id":2}, 
 {"id":154,"group_name":"epc","channel":"voltage-c","type":"fast-transient","value":null,"threshold":null,"start_timestamp":1635620042743479296,"end_timestamp":1635620042760279296,"event_id":"2021-10-30_18-54-02-743_voltage-c_fast-transient","sensor_id":2}]

export default apiEvent;